import { ICustomApiError } from 'types/error';

export const logApiError = (err: ICustomApiError, request?: string) => {
  let errorMessage = `🔴 API ERROR`;

  if (err.statusCode) errorMessage += ` ${err.statusCode}`;
  if (request) errorMessage += ` | ${request}`;
  if (err.message) errorMessage += `: ${err.message}`;

  console.error(errorMessage);
};
