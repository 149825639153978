/* eslint-disable @typescript-eslint/no-explicit-any */
// form
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isAfter, parseISO } from 'date-fns';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { CustomDatePickerProps } from './DatePicker.types';
import { useTranslations } from 'hooks';

export const defaultDateValidation = (dateString: string) => {
  // 1st January 1900 as minDate
  const minDate = parseISO('19000101T000000+0100');
  const targetDate = Date.parse(dateString);
  const isDateValid = !!targetDate && isAfter(new Date(targetDate), minDate);
  return isDateValid;
};

const RHFDatePicker = ({
  name,
  label,
  rules,
  required = false,
  ...other
}: CustomDatePickerProps) => {
  const { control } = useFormContext();
  const i18n = useTranslations();

  const defaultValidateOrMessage: RegisterOptions['validate'] = (
    datestring: string
  ) => defaultDateValidation(datestring) || i18n.general.errors.invalidDate;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: defaultValidateOrMessage,
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          inputFormat="dd-MM-yyyy"
          toolbarFormat="EEE, dd LLL"
          showToolbar
          {...other}
          label={label}
          value={field.value}
          onChange={(newValue: any) => field.onChange(newValue)}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              error={!!error}
              helperText={error?.message}
              required={required}
            />
          )}
        />
      )}
    />
  );
};

export { RHFDatePicker };
