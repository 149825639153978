const CheckIcon = ({ fill = '#212B36' }) => {
  return (
    <svg
      width="40"
      height="30"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.055 23.396L4.02003 14.361L0.943359 17.416L13.055 29.5277L39.055 3.52766L36 0.472656L13.055 23.396Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckIcon;
