import { IRequestFilters } from 'types/httpClient';

export const createFiltersQuery = (filter: IRequestFilters) => {
  const parsedQuery: string[][] = [];
  Object.entries(filter).map(([k, v]) => {
    if (v || v === 0) {
      typeof v === 'object'
        ? v
            .filter((v: string) => v !== 'all')
            .map((s: number) => parsedQuery.push([k, s.toString()]))
        : parsedQuery.push([k, v.toString()]);
    }
  });
  const query = parsedQuery?.length && new URLSearchParams(parsedQuery);
  return query ? `?${new URLSearchParams(query)}` : '';
};
