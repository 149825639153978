import { IntlShape } from 'react-intl';

export const exportTranslations = (intl: IntlShape) => ({
  export: {
    accreditations: {
      surname: intl.formatMessage({
        defaultMessage: 'Cognome',
        id: 'export.accreditations.surname',
      }),
      name: intl.formatMessage({
        defaultMessage: 'Nome',
        id: 'export.accreditations.name',
      }),
      birthDate: intl.formatMessage({
        defaultMessage: 'Data Nascita',
        id: 'export.accreditations.birthDate',
      }),
      city: intl.formatMessage({
        defaultMessage: 'Luogo Nascita',
        id: 'export.accreditations.city',
      }),
      gender: intl.formatMessage({
        defaultMessage: 'Sesso',
        id: 'export.accreditations.gender',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Categoria',
        id: 'export.accreditations.category',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Testata',
        id: 'export.accreditations.organisation',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        id: 'export.accreditations.email',
      }),
    },
    photographers: {
      surname: intl.formatMessage({
        defaultMessage: 'Cognome',
        id: 'export.photographers.surname',
      }),
      name: intl.formatMessage({
        defaultMessage: 'Nome',
        id: 'export.photographers.name',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Testata',
        id: 'export.photographers.organisation',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Categoria',
        id: 'export.photographers.category',
      }),
      birthDate: intl.formatMessage({
        defaultMessage: 'Data Nascita',
        id: 'export.photographers.birthDate',
      }),
      council: intl.formatMessage({
        defaultMessage: 'Luogo Nascita',
        id: 'export.photographers.council',
      }),
      nation: intl.formatMessage({
        defaultMessage: 'Nazione Nascita',
        id: 'export.photographers.nation',
      }),
      gender: intl.formatMessage({
        defaultMessage: 'Sesso',
        id: 'export.photographers.gender',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        id: 'export.photographers.email',
      }),
    },
  },
});
