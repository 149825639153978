const ExpandMoreIcon = ({ fillColor = 'none' }: { fillColor?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 13.3333C9.80521 13.3337 9.61652 13.2659 9.46659 13.1417L4.46659 8.97499C4.11221 8.68044 4.0637 8.15438 4.35826 7.79999C4.65281 7.44561 5.17887 7.39711 5.53326 7.69166L9.99992 11.425L14.4666 7.82499C14.6388 7.68519 14.8595 7.61977 15.0801 7.64323C15.3006 7.66669 15.5027 7.77709 15.6416 7.94999C15.7959 8.12321 15.871 8.35289 15.8488 8.58379C15.8267 8.8147 15.7093 9.02592 15.5249 9.16666L10.5249 13.1917C10.3707 13.2963 10.1858 13.3461 9.99992 13.3333Z"
        fill="#212B36"
      />
    </svg>
  );
};

export default ExpandMoreIcon;
