import CryptoJS from 'crypto-js';
import { useCallback } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector, persist } from 'zustand/middleware';

import * as API from 'constants/api';
import { ILoginResponse, IRefreshTokensResponse } from 'types/response';
import { AuthStoreDefaultValues, IAuthStore } from 'types/stores';
import { IUser, IUserToken, UserLitteralTypes } from 'types/user';

const isDevelopment = process.env.NODE_ENV === 'development';

const AUTH_STORE_STORAGE_KEY = 'acmilan-session';

const DEFAULT_VALUES: AuthStoreDefaultValues = {
  isAuthenticated: false,
  isAdmin: false,
  isChangingPassword: false,
  isLoggingOut: false,
  remember: false,
  user: null,
  tokens: null,
};

const resetDefaults = () => useStore.setState({ ...DEFAULT_VALUES });

const handleLogout = () => {
  resetDefaults();
  useStore.setState({ isLoggingOut: true });
};

const storeSession = (sessionData: ILoginResponse, remember = false) => {
  useStore.setState({
    user: sessionData.user,
    tokens: sessionData.token,
    remember,
    isAdmin: sessionData.user.type === UserLitteralTypes.ADMIN,
  });
};

const storeUser = (userData: IUser) => {
  useStore.setState({
    user: userData,
    isAdmin: userData.type === UserLitteralTypes.ADMIN,
  });
};

const storeTokens = (tokens: IUserToken) => {
  useStore.setState({
    tokens,
  });
};

const refreshTokens = async (): Promise<void> => {
  const refreshToken = useStore.getState().tokens?.refreshToken;

  if (!refreshToken) return;
  const body = JSON.stringify({
    refreshToken,
  });

  const res = await fetch(
    `${process.env.REACT_APP_HOST}${API.REFRESH_TOKENS}`,
    {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body,
    }
  );

  const refreshedTokens: IRefreshTokensResponse = await res.json();
  const updatedUserTokens: IUserToken = {
    ...refreshedTokens,
    mustChangePassword: false,
  };
  storeTokens(updatedUserTokens);
};

const deserialize = (rawData: string) => {
  const parsedData = isDevelopment
    ? JSON.parse(rawData)
    : JSON.parse(
        CryptoJS.AES.decrypt(
          rawData,
          process.env.REACT_APP_ENCRYPTION_KEY as string
        ).toString(CryptoJS.enc.Utf8)
      );

  if (parsedData.state.tokens?.mustChangePassword) return { ...DEFAULT_VALUES };

  return parsedData;
};

const serialize = (state: unknown) => {
  if (isDevelopment) return JSON.stringify(state);
  return CryptoJS.AES.encrypt(
    JSON.stringify(state),
    process.env.REACT_APP_ENCRYPTION_KEY as string
  ).toString();
};

const useStore = create(
  subscribeWithSelector(
    persist(
      (): IAuthStore => ({
        ...DEFAULT_VALUES,
        storeSession,
        storeUser,
        storeTokens,
        refreshTokens,
        handleLogout,
        reset: resetDefaults,
      }),
      {
        name: AUTH_STORE_STORAGE_KEY,
        deserialize,
        serialize,
      }
    )
  )
);

useStore.subscribe(
  state => state.tokens,
  tokens => useStore.setState({ isAuthenticated: !!tokens })
);

const useAuthSelector = () => useStore(useCallback(state => state, []));
const useIsAdmin = () => useStore(state => state.isAdmin);

export { useAuthSelector, useIsAdmin, useStore as useAuthStore };
