import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import * as PATHS from 'constants/paths';
import { useGlobalSelector } from 'stores';
import { ICustomi18n } from 'types/intl';

export const getCustomBreadcrumbs = (
  i18n: ICustomi18n
): BreadcrumbsRoute<string>[] => [
  /**
   * Force Breadcrumbs where the slug is not the title desired
   * (e.g.: /users-management ==> 'Gestione  Utenti')
   */
  {
    path: PATHS.USERS_MANAGEMENT_ROOT,
    breadcrumb: i18n.usersManagement.configuration.title,
  },
  {
    path: PATHS.USERS_MANAGEMENT_CREATE_ADMIN,
    breadcrumb: i18n.usersManagement.configuration.adminCreateTitle,
  },
  {
    path: PATHS.USERS_MANAGEMENT_EDIT_ADMIN,
    breadcrumb: i18n.usersManagement.configuration.adminEditTitle,
  },
  {
    path: PATHS.USERS_MANAGEMENT_CREATE_JOURNALIST,
    breadcrumb: i18n.usersManagement.configuration.journalistCreateTitle,
  },
  {
    path: PATHS.USERS_MANAGEMENT_EDIT_JOURNALIST,
    breadcrumb: i18n.usersManagement.configuration.journalistEditTitle,
  },
  {
    path: PATHS.USERS_MANAGEMENT_CREATE_SECRETARIAT,
    breadcrumb: i18n.usersManagement.configuration.secretariatCreateTitle,
  },
  {
    path: PATHS.USERS_MANAGEMENT_EDIT_SECRETARIAT,
    breadcrumb: i18n.usersManagement.configuration.secretariatEditTitle,
  },
  {
    path: PATHS.USERS_MANAGEMENT_LIST_SECRETARIAT,
    breadcrumb: i18n.usersManagement.configuration.secretariatListTitle,
  },
  {
    path: PATHS.CALENDAR_ROOT,
    breadcrumb: i18n.general.navbar.calendar,
  },
  {
    path: PATHS.CALENDAR_MATCH_ID,
    breadcrumb: i18n.calendar.navigation.breadcrumbs.matchId,
  },
  {
    path: PATHS.CALENDAR_MATCH_ID_MAP,
    breadcrumb: i18n.calendar.map.title,
  },
  {
    path: PATHS.CALENDAR_CREDIT_MANAGEMENT_ID,
    breadcrumb: i18n.calendar.navigation.breadcrumbs.creditManagement,
  },
  {
    path: PATHS.CALENDAR_CREDIT_MANAGEMENT_IMPORT_RECAP,
    breadcrumb: i18n.calendar.navigation.breadcrumbs.importRecap,
  },
  {
    path: PATHS.CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID,
    breadcrumb: i18n.calendar.navigation.breadcrumbs.creditDetail,
  },
  {
    path: PATHS.CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID_MAP,
    breadcrumb: i18n.calendar.map.title,
  },
  {
    path: PATHS.CONFIG_ROOT,
    breadcrumb: i18n.general.navbar.config,
  },
  {
    path: PATHS.CONFIG_SEASON_CREATE,
    breadcrumb: i18n.seasonConfig.pageTitles.newSeason,
  },
  {
    path: PATHS.CONFIG_SEASON_ID,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.seasonId,
  },
  {
    path: PATHS.CONFIG_COMPETITION_ID,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.competitionSlug,
  },
  {
    path: PATHS.CONFIG_COMPETITION_EDIT_MATCH,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.matchId,
  },
  {
    path: PATHS.PROFILE_ROOT,
    breadcrumb: i18n.profile.title,
  },
  {
    path: PATHS.CALENDAR_ROOT_FO,
    breadcrumb: i18n.general.navbar.calendar,
  },
  {
    path: PATHS.CONTACTS,
    breadcrumb: i18n.contacts.title,
  },
  {
    path: PATHS.FAQ,
    breadcrumb: i18n.faq.title,
  },
  {
    path: PATHS.CALENDAR_FO_CREDIT_MANAGEMENT,
    breadcrumb: i18n.calendar.recapSecretariat.breadcrumbs,
  },
];

export const excludePaths = [
  PATHS.HOME,
  PATHS.USERS_MANAGEMENT_EDIT_ADMIN_ID,
  PATHS.USERS_MANAGEMENT_EDIT_JOURNALIST_ID,
  PATHS.USERS_MANAGEMENT_EDIT_SECRETARIAT_ID,
  PATHS.USERS_MANAGEMENT_LIST_SECRETARIAT_ID,
  PATHS.CALENDAR_MATCH,
  PATHS.CALENDAR_CREDIT_MANAGEMENT_REQUEST,
  PATHS.CALENDAR_MATCH_FO,
  PATHS.CALENDAR_MATCH_ID_FO,
];
