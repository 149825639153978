import { Button, DialogActions, styled } from '@mui/material';
import { ActionsWrapperProps, ModalButtonProps } from './ModalActions.types';

export const ActionsWrapper = styled(DialogActions, {
  shouldForwardProp: prop => prop !== 'showActionsDivider',
})<ActionsWrapperProps>(({ showActionsDivider, theme }) => ({
  flexWrap: 'wrap',
  borderTop: showActionsDivider
    ? `1px solid ${theme.palette.grey[500_24]}`
    : 'none',
}));

export const ModalButton = styled(Button, {
  shouldForwardProp: prop =>
    !['fullWidthButtons', 'stretchButtons'].includes(prop as string),
})<ModalButtonProps>(({ stretchButtons, fullWidthButtons, theme }) => ({
  flex: stretchButtons ? '1' : 'unset',
  ...(fullWidthButtons && {
    width: '100%',
    marginBottom: theme.spacing(1),
  }),
  '&.MuiButton-root.MuiButtonBase-root': {
    ...(fullWidthButtons && {
      marginLeft: 0,
    }),
  },
}));
