import { Typography } from '@mui/material';
import { ChildrenWrapper, Wrapper } from './ColumnElement.styles';
import { IColumnElementProps } from './ColumnElement.types';
import { useResponsive } from 'hooks';

const ColumnElement = ({
  subtitle,
  title,
  children,
  isExtra = true,
  capitalizeSubtitle = true,
  ...wrapperProps
}: IColumnElementProps) => {
  const lgUp = useResponsive('up', 'lg');

  return (
    <Wrapper isExtra={isExtra} {...wrapperProps}>
      {children ? (
        <ChildrenWrapper>{children}</ChildrenWrapper>
      ) : (
        <Typography
          noWrap
          variant={isExtra ? 'h6' : 'h4'}
          sx={{ width: '100%', display: 'inline-block' }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant={lgUp ? (isExtra ? 'body2' : 'body1') : 'caption'}
          sx={{ textTransform: capitalizeSubtitle ? 'capitalize' : 'none' }}
        >
          {subtitle}
        </Typography>
      )}
    </Wrapper>
  );
};

export { ColumnElement };
