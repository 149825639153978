const ArrowRightIcon = ({ fill = 'black' }: { fill?: string }) => (
  <svg
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.964355 10.59L5.5655 6L0.964355 1.41L2.38086 0L8.40857 6L2.38086 12L0.964355 10.59Z"
      fill={fill}
    />
  </svg>
);

export default ArrowRightIcon;
