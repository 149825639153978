import { Page, Text, View, Document } from '@react-pdf/renderer';
import { groupBy } from 'lodash';
import { globalStyles } from '../Pdf.styles';
import { IPdfCommonProps } from '../Pdf.types';
import { styles } from './SingleListPdf.styles';
import { PdfLogo } from 'components';
import { IAccreditation, SeatType } from 'types/accreditations';
import { UserCategory, UserLitteralCategory } from 'types/user';
import { fDateFullTZ, fTimeToTimeString } from 'utils/formatTime';
import { getUserTranslatedCategory } from 'utils/user';

const SingleListPdf = ({ exportData, i18n }: IPdfCommonProps) => {
  const { accreditations, opponent, dateTime, time } = exportData;
  const groupedAccreditations = groupBy(
    // Sort accreditations alphabetically by user surname
    accreditations.sort((a, b) => a.user.surname.localeCompare(b.user.surname)),
    'category'
  ) as Record<UserCategory, IAccreditation[]>;

  const getSeatName = (accreditation: IAccreditation) => {
    const isPhotographer = accreditation.seatType === SeatType.SIDELINE;
    const isObserver = accreditation.seatType === SeatType.OBSERVER;
    const isAssignedWithoutSeat =
      accreditation.seatType === SeatType.NON_ASSEGNATO;
      
    if (isAssignedWithoutSeat)
      return i18n.calendar.creditManagement.credits.assignedWithoutSeat;

    if (isPhotographer) return i18n.calendar.creditManagement.credits.sideline;

    return `${isObserver ? i18n.calendar.map.seat.observerPrefix : ''}${
      accreditation.seat
    }`;
  };

  const categoriesOrderMap: {
    [key: string]: number;
  } = {
    [UserLitteralCategory.ITALIAN_PHOTOGRAPHER]: 0,
    [UserLitteralCategory.FOREIGN_PHOTOGRAPHER]: 1,
    [UserLitteralCategory.ITALIAN_PRESS]: 2,
    [UserLitteralCategory.FOREIGN_PRESS]: 3,
    [UserLitteralCategory.GUEST_PRESS_OFFICE]: 4,
    [UserLitteralCategory.OBSERVER_STAND]: 5,
    [UserLitteralCategory.WEB_MASTER]: 6,
    [UserLitteralCategory.RADIO]: 7,
    [UserLitteralCategory.TV]: 8,
    [UserLitteralCategory.TV_TECHNICIAN]: 9,
  };

  return (
    <Document>
      {Object.entries(groupedAccreditations)
        // Sort accreditations group
        .sort(
          ([aKey], [bKey]) =>
            categoriesOrderMap[aKey] - categoriesOrderMap[bKey]
        )
        .map(([key, value]) => (
          <Page size="A4" style={globalStyles.page} key={key}>
            <View style={styles.logoContainer}>
              <View>
                <View style={[globalStyles.section, styles.title]}>
                  <Text>{i18n.calendar.match.export.singleListPdf.title}</Text>
                </View>
                <View style={[globalStyles.section, styles.matchName]}>
                  <Text>Milan - {opponent}</Text>
                </View>
                <View style={[globalStyles.section, styles.matchInfo]}>
                  <Text>{fDateFullTZ(dateTime)}</Text>
                </View>
                <View style={[globalStyles.section, styles.matchInfo]}>
                  <Text>{fTimeToTimeString(time)}</Text>
                </View>
              </View>
              <PdfLogo style={styles.logo} />
            </View>

            <View>
              <View style={[globalStyles.section, styles.categoryName]}>
                <Text>
                  {getUserTranslatedCategory(i18n, value[0].category)}
                </Text>
              </View>
              <View style={[globalStyles.section, styles.tableHeader]}>
                <Text style={[styles.columnTitle, styles.nameHeader]}>
                  {i18n.calendar.match.export.singleListPdf.table.name}
                </Text>
                <Text style={[styles.columnTitle, styles.newspaperHeader]}>
                  {i18n.calendar.match.export.singleListPdf.table.newspaper}
                </Text>
                <Text style={[styles.columnTitle, styles.seatHeader]}>
                  {i18n.calendar.match.export.singleListPdf.table.seat}
                </Text>
              </View>
              {value
                // Sort accreditations alphabetically by organisation
                .sort((a, b) => a.organisation.localeCompare(b.organisation))
                .map(a => (
                  <View
                    key={a.user.id}
                    style={[globalStyles.section, styles.tableRow]}
                  >
                    <Text style={[styles.tableCell, styles.nameCell]}>
                      {a.user.name} {a.user.surname}
                    </Text>
                    <Text style={[styles.tableCell, styles.newspaperCell]}>
                      {a.organisation}
                    </Text>
                    <Text style={[styles.tableCell, styles.seatCell]}>
                      {getSeatName(a)}
                    </Text>
                  </View>
                ))}
            </View>
          </Page>
        ))}
    </Document>
  );
};

export { SingleListPdf };
