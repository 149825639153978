import { Box, Link, Typography } from '@mui/material';
import { RootStyle } from './NavbarAccount.styles';
import { INavbarAccountProps } from './NavbarAccount.types';
import { Avatar } from 'components';
import { useTranslations } from 'hooks';
import { useAuthSelector } from 'stores';
import { GREY } from 'theme/palette';

const NavbarAccount = ({ isCollapse }: INavbarAccountProps) => {
  const i18n = useTranslations();
  const { user } = useAuthSelector();

  const userFullName =
    user?.name && user?.surname ? `${user.name} ${user.surname}` : undefined;

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar fullName={userFullName} alt={userFullName} />

        <Box
          sx={{
            ml: 2,
            transition: theme =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            width: 'calc(100% - 40px)',
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {userFullName}
          </Typography>
          {user?.type && (
            <Typography variant="body2" noWrap sx={{ color: GREY[500] }}>
              {i18n.general.user.roles[user.type]}
            </Typography>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
};

export { NavbarAccount };
