import { matchPath } from 'react-router-dom';
import * as PATHS from 'constants/paths';
import { useGlobalStore } from 'stores';
import { IDynamicBreadcrumb } from 'types/stores';

export function isExternalLink(path: string) {
  return path.includes('http');
}

export function getActive(path: string, pathname: string) {
  return path
    ? !!matchPath({ path: path, end: path === PATHS.ROOT }, pathname)
    : false;
}

export const makePath = (...pathSlices: string[]): string => {
  const path = pathSlices.reduce(
    (constructedPath, slice) => `${constructedPath}${slice}`,
    ''
  );
  return path;
};

export const interpolatePathData = (
  path: string,
  data: { [key: string]: string | number | undefined }
): string => {
  const interpolatedPath = Object.entries(data).reduce(
    (constructedPath, [key, value]) =>
      constructedPath.replace(`:${key}`, String(value)),
    path
  );
  return interpolatedPath;
};

export const updateDynamicBreadcrumb = (
  breads: {
    key: keyof IDynamicBreadcrumb;
    value: string;
  }[]
) => {
  breads.map(b =>
    useGlobalStore.setState(prev => ({
      ...prev,
      dynamicBreadcrumbs: {
        ...prev.dynamicBreadcrumbs,
        [b.key]: b.value,
      },
    }))
  );
};
