import { Stack, Typography, TypographyProps } from '@mui/material';
import { IUserInfoRowProps } from './UserInfoRow.types';
import { PersonOutlineIcon } from 'assets';

const UserInfoRow = ({
  name,
  surname,
  email,
  organisation,
  category,
}: IUserInfoRowProps) => {
  const commonFlexProps = { flex: 1 };
  const typographyProps: TypographyProps = {
    variant: 'body2',
    noWrap: true,
    sx: { ...commonFlexProps },
  };

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={3}
      sx={{ mt: '10px', width: '100%' }}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
        sx={{ ...commonFlexProps }}
      >
        <PersonOutlineIcon />
        <Typography {...typographyProps} sx={{ width: 110 }}>
          {name} {surname}
        </Typography>
      </Stack>
      <Typography {...typographyProps} sx={{ flex: 2 }}>
        {email}
      </Typography>
      <Typography {...typographyProps}>{organisation}</Typography>
      {category && <Typography {...typographyProps}>{category}</Typography>}
    </Stack>
  );
};
export { UserInfoRow };
