// ! TODO: Check types with BE
export interface IMatch {
  id: number;
  date: string;
  time: string;
  dateTime: string;
  status: MatchStatus;
  opponent: string;
}

// ! TODO: Check types with BE
export enum MatchStatus {
  CONFIRMED = 'Confirmed',
  TOBECONFIRMED = 'ToConfirm',
}

export type MatchEnumsStatus = `${MatchStatus}`;

export type MatchEnumsCompetition = `${MatchCompetition}`;

export type NewMatchFormValues = {
  homeTeam: string;
  opponent: string;
  date: string;
  time: string;
  stage: string;
  status: string;
  competition: string;
  seasonId: string;
  accreditationDateLimit: MatchInput['accreditationDateLimit'];
};

export type MatchInput = {
  opponent: string;
  date: string;
  time: string;
  stage: string;
  status: MatchStatus;
  competition: MatchCompetition;
  seasonId: number;
  accreditationDateLimit: string | null;
};

export interface IMatchCustomOutput {
  success: boolean;
}

export enum MatchCompetition {
  CAMPIONATO = 'Serie A',
  COPPA_ITALIA = 'Coppa Italia',
  CHAMPIONS_LEAGUE = 'Champions League',
  EUROPA_LEAGUE = 'Europa League',
  SUPERCOPPA_ITALIANA = 'Supercoppa Italiana',
  FRIENDLY = 'Amichevoli',
  CONFERENCE_LEAGUE = 'Conference League',
}
