import { DialogContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ModalProps } from './Modal.types';

export const ModalBody = styled(DialogContent, {
  shouldForwardProp: prop => prop !== 'centerContent',
})<Pick<ModalProps, 'centerContent'>>(({ centerContent, theme }) => ({
  minWidth: 360,
  marginTop: theme.spacing(2),
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[600],
  paddingBlock: theme.spacing(2),
  overflowX: 'hidden',
  ...(centerContent && {
    textAlign: 'center',
  }),
}));

export const CloseIconWrapper = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2.5),
  right: theme.spacing(3),
}));
