import useFetch from 'use-http';
import { MATCHES } from 'constants/api';
import { useGlobalSelector } from 'stores';
import { ICalendarMatch } from 'types/accreditations';
import {
  IHttpHelperExtendedProps,
  IHttpMutateHelperProps,
} from 'types/httpClient';
import { MatchInput, IMatch, IMatchCustomOutput } from 'types/match';
import { logApiError } from 'utils/api';
import { createFiltersQuery } from 'utils/data';

const useMatches = () => {
  const fetchHelper = useFetch();
  const { get, post, put } = fetchHelper;
  const { showLoader, hideLoader } = useGlobalSelector();

  const listMatches = async ({
    useLoader,
    setData,
    body = {},
  }: IHttpMutateHelperProps<
    Array<IMatch>,
    object
  >): Promise<Array<IMatch> | null> => {
    let data: Array<IMatch>;
    try {
      useLoader && showLoader();
      const params = body ? createFiltersQuery(body) : '';
      data = await get(MATCHES + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-matches');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const createMatch = async ({
    useLoader,
    body,
  }: IHttpMutateHelperProps<IMatch, MatchInput>): Promise<IMatch | null> => {
    try {
      useLoader && showLoader();
      const response = await post(MATCHES, body);
      return response;
    } catch (err) {
      logApiError(err, 'create-match');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const editMatch = async ({
    useLoader,
    body,
    id,
  }: IHttpMutateHelperProps<IMatch, MatchInput>): Promise<IMatch | null> => {
    try {
      useLoader && showLoader();
      const response = await put(`${MATCHES}/${id}`, body);
      return response;
    } catch (err) {
      logApiError(err, 'create-match');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const deleteMatch = async ({
    useLoader,
    id,
  }: IHttpHelperExtendedProps<IMatch>): Promise<IMatchCustomOutput | null> => {
    try {
      useLoader && showLoader();
      await fetchHelper.delete(`${MATCHES}/${id}`);
      return { success: true };
    } catch (err) {
      logApiError(err, 'delete-match');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const getMatch = async ({
    useLoader,
    setData,
    id,
  }: IHttpHelperExtendedProps<
    ICalendarMatch | undefined
  >): Promise<ICalendarMatch | null> => {
    let data: ICalendarMatch;
    try {
      useLoader && showLoader();
      data = await get(`${MATCHES}/${id}`);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-match');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    listMatches,
    createMatch,
    deleteMatch,
    getMatch,
    editMatch,
  };
};

export { useMatches };
