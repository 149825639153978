import { userCategoriesMapping } from 'constants/user';
import { ICustomi18n, Translations } from 'types/intl';
import {
  UserEnumStatus,
  UserEnumsCategory,
  UserLitteralCategory,
  UserLitteralStatus,
} from 'types/user';

type StatusLabel = {
  variant: 'info' | 'success' | 'warning' | 'error';
  label: string;
};

export const getUserStatusLabel = (
  status: UserEnumStatus,
  enabled: boolean,
  i18n: ICustomi18n
): StatusLabel => {
  //Is Approved if enabled true && status App
  //Is Pending if enabled true && status Pending
  //Is Revoked if enabled false
  //Is New if status === null
  if (enabled) {
    let statusLabel: StatusLabel = {
      variant: 'info',
      label: i18n.user.status.new,
    };
    switch (status) {
      case UserLitteralStatus.APPROVED:
        statusLabel = { variant: 'success', label: i18n.user.status.approved };
        break;
      case UserLitteralStatus.PENDING:
        statusLabel = {
          variant: 'warning',
          label: i18n.user.status.tobeApproved,
        };
        break;
      case UserLitteralStatus.TOBEAPPROVED:
        statusLabel = {
          variant: 'warning',
          label: i18n.user.status.tobeConfirmed,
        };
        break;
      default:
        break;
    }
    return statusLabel;
  } else {
    return { variant: 'error', label: i18n.user.status.revoked };
  }
};

export const getUserTranslatedCategory = (
  i18n: Translations,
  rawCategory?: UserLitteralCategory | UserEnumsCategory
) => {
  const translatedCategories = userCategoriesMapping(i18n);
  const translatedCategory = translatedCategories.find(
    c => c.id === rawCategory
  )?.name;
  return translatedCategory || rawCategory || '';
};
