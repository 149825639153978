import { useEffect, useState } from 'react';
import { useGlobalSelector } from 'stores';

const usePromises = (inputPromises: Promise<unknown>[] = []) => {
  const [promises, setPromises] = useState(inputPromises);
  const { showLoader, hideLoader } = useGlobalSelector();

  useEffect(() => {
    const handlePromises = async () => {
      showLoader();
      await Promise.all(promises);
      hideLoader();
      setPromises([]);
    };
    if (promises.length) handlePromises();
  }, [promises]);

  return { setPromises };
};

export { usePromises };
