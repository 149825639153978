import { Theme } from '@mui/material/styles';

export default function TextField(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputAdornment-positionEnd ': {
            marginInline: theme.spacing(2),
          },
        },
      },
    },
  };
}
