import { OptionsObject, useSnackbar as useNotistack } from 'notistack';

const useSnackbar = () => {
  const notistackMethods = useNotistack();

  const { enqueueSnackbar } = notistackMethods;

  const successSnackbar = (message: string, options?: OptionsObject) =>
    enqueueSnackbar(message, { variant: 'success', ...options });

  const errorSnackbar = (message: string, options?: OptionsObject) =>
    enqueueSnackbar(message, { variant: 'error', ...options });

  return {
    successSnackbar,
    errorSnackbar,
    ...notistackMethods,
  };
};

export { useSnackbar };
