import { styled } from '@mui/material';
import { ColumnElementWrapperProps } from './ColumnElement.types';

export const Wrapper = styled('div', {
  shouldForwardProp: prop => !['isExtra', 'inverese'].includes(prop as string),
})<ColumnElementWrapperProps>(({ inverse, theme, isExtra }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: inverse ? 'flex-end ' : 'flex-start',
  justifyContent: 'center',
  flex: 1,
  ...(isExtra && { marginInline: theme.spacing(3) }),

  '&:last-child': {
    minWidth: 'unset',
    flex: 'unset',
  },
}));

export const ChildrenWrapper = styled('div')({
  minHeight: 36,
  alignItems: 'center',
  display: 'flex',
});
