import { DialogTitle, Typography, useTheme } from '@mui/material';
import { ModalTitleProps } from './ModalTitle.types';

const ModalTitle = ({
  title,
  subtitle,
  centerContent = false,
}: ModalTitleProps) => {
  const theme = useTheme();
  return (
    <DialogTitle
      sx={{ mt: theme.spacing(1) }}
      align={centerContent ? 'center' : 'left'}
    >
      <Typography variant="h6" component="span">
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body2" marginTop="-4px">
          {subtitle}
        </Typography>
      )}
    </DialogTitle>
  );
};
export { ModalTitle };
