import { ICustomi18n } from 'types/intl';
import { UserLitteralStatus } from 'types/user';

const userStatusMapping = (i18n: ICustomi18n) => [
  {
    id: UserLitteralStatus.APPROVED,
    name: i18n.general.user.status.approved,
  },
  { id: UserLitteralStatus.REVOKED, name: i18n.general.user.status.revoked },
];

const userFilterStatusMapping = (i18n: ICustomi18n) => [
  {
    id: UserLitteralStatus.NEW,
    name: i18n.general.user.status.new,
  },
  {
    id: UserLitteralStatus.TOBEAPPROVED,
    name: i18n.general.user.status.tobeConfirmed,
  },
  {
    id: UserLitteralStatus.PENDING,
    name: i18n.general.user.status.tobeApproved,
  },
  { id: UserLitteralStatus.REVOKED, name: i18n.general.user.status.revoked },
  {
    id: UserLitteralStatus.APPROVED,
    name: i18n.general.user.status.approved,
  },
];

export { userStatusMapping, userFilterStatusMapping };
