const EditIcon = ({ fill = 'black' }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0585 7.51667L12.8251 8.28333L5.27513 15.8333H4.50846V15.0667L12.0585 7.51667ZM15.0585 2.5C14.8501 2.5 14.6335 2.58333 14.4751 2.74167L12.9501 4.26667L16.0751 7.39167L17.6001 5.86667C17.9251 5.54167 17.9251 5.01667 17.6001 4.69167L15.6501 2.74167C15.4835 2.575 15.2751 2.5 15.0585 2.5ZM12.0585 5.15833L2.8418 14.375V17.5H5.9668L15.1835 8.28333L12.0585 5.15833Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;
