/* eslint-disable @typescript-eslint/no-explicit-any */
// form
import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';

import { CustomTimePickerProps } from './TimePicker.types';
import { useTranslations } from 'hooks';

const RHFTimePicker = ({
  name,
  label,
  rules,
  required = false,
  ...other
}: CustomTimePickerProps) => {
  const { control } = useFormContext();
  const i18n = useTranslations();

  const defaultTimeValidation = (value: string) =>
    !!Date.parse(value) || i18n.general.errors.invalidTimeFormat;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: defaultTimeValidation, ...rules }}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          inputFormat="HH mm"
          showToolbar
          {...other}
          label={label}
          value={field.value}
          onChange={(newValue: any) => field.onChange(newValue)}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              error={!!error}
              helperText={error?.message}
              required={required}
            />
          )}
        />
      )}
    />
  );
};

export { RHFTimePicker };
