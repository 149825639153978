import { styled, Theme } from '@mui/material/styles';

import { NAVBAR } from 'config';
import cssStyles from 'utils/cssStyles';

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export const getNavbarPaperStyles =
  (isCollapse = false, collapseHover = false, isFrontOffice = false) =>
  (theme: Theme) => ({
    width: NAVBAR.DASHBOARD_WIDTH,
    borderRightStyle: 'dashed',
    bgcolor: 'background.navBar',
    color: theme.palette.common.white,
    ...(isFrontOffice && {
      position: 'absolute',
      bottom: 0,
    }),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.standard,
    }),
    ...(isCollapse && {
      width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
    ...(collapseHover && {
      ...cssStyles(theme).bgBlur({
        color: theme.palette.secondary.main,
        opacity: 0.85,
        blur: 8,
      }),
      boxShadow: theme.customShadows.z24,
    }),
  });
