import { Page, Text, View, Document } from '@react-pdf/renderer';
import { globalStyles } from '../Pdf.styles';
import { IPdfCommonProps } from '../Pdf.types';
import { styles } from './PlaceHoldersPdf.styles';
import { PdfLogo } from 'components/pdf/PdfLogo/PdfLogo';
import { IAccreditation, SeatType } from 'types/accreditations';
import { MatchCompetition } from 'types/match';
import { fDateFullTZ, fTimeToTimeString } from 'utils/formatTime';

const PlaceHoldersPdf = ({ exportData, i18n }: IPdfCommonProps) => {
  const { accreditations, opponent, dateTime, time, competition } = exportData;

  const journalistsAccreditations = accreditations.filter(a => !!a.seat);

  const getSeatName = (accreditation: IAccreditation) => {
    const isPhotographer = accreditation.seatType === SeatType.SIDELINE;
    const isObserver = accreditation.seatType === SeatType.OBSERVER;

    if (isPhotographer) return i18n.calendar.creditManagement.credits.sideline;

    return `${i18n.calendar.match.export.placeHoldersPdf.seat} ${
      isObserver ? i18n.calendar.map.seat.observerPrefix : ''
    }${accreditation.seat}`;
  };

  return (
    <Document>
      {journalistsAccreditations.map(accreditation => (
        <Page key={accreditation.id} size="A4" style={globalStyles.page}>
          <View style={{ flexGrow: 1 }} wrap={false}>
            <View style={styles.logoContainer}>
              <PdfLogo />
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.newspaper,
              ]}
            >
              <Text>{accreditation.organisation}</Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.name,
              ]}
            >
              <Text>
                {accreditation.user.name} {accreditation.user.surname}
              </Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.seat,
              ]}
            >
              <Text>{getSeatName(accreditation)}</Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.matchName,
              ]}
            >
              <Text>MILAN - {opponent}</Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.matchInfo,
              ]}
            >
              <Text>MILANO - STADIO SAN SIRO</Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.matchInfo,
              ]}
            >
              <Text>{fDateFullTZ(dateTime)}</Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.matchInfo,
              ]}
            >
              <Text>{fTimeToTimeString(time)}</Text>
            </View>
            <View
              style={[
                globalStyles.section,
                globalStyles.centeredSection,
                styles.matchInfo,
              ]}
            >
              <Text>
                {competition === MatchCompetition.CAMPIONATO
                  ? 'Campionato'
                  : competition}
              </Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export { PlaceHoldersPdf };
