import { useCallback } from 'react';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { defaultLang, defaultLocale, getLangByLocale } from 'config';
import { Locales } from 'types/intl';
import { IGlobalStore, IGlobalStoreDefaultValues } from 'types/stores';

const GLOBAL_STORE_STORAGE_KEY = 'acmilan-global';

const defaulValues: IGlobalStoreDefaultValues = {
  activeLocale: defaultLocale,
  activeLang: defaultLang,
  loading: false,
  requestedLocation: null,
  dynamicBreadcrumbs: { seasonId: '', competitionSlug: '', matchId: '' },
};

const useStore = create(
  subscribeWithSelector(
    persist<IGlobalStore, [], [], Partial<IGlobalStore>>(
      (set): IGlobalStore => ({
        ...defaulValues,
        setActiveLocale: (activeLocale: Locales) => set({ activeLocale }),
        setActiveLang: (locale: Locales) =>
          set({ activeLang: getLangByLocale(locale) }),
        showLoader: () => set(() => ({ loading: true })),
        hideLoader: () => set(() => ({ loading: false })),
      }),
      {
        name: GLOBAL_STORE_STORAGE_KEY,
        partialize: state => ({ activeLocale: state.activeLocale }),
        merge: (persistedState, currentState) => {
          const typedPersistedState = persistedState as Partial<IGlobalStore>;
          return {
            ...typedPersistedState,
            ...currentState,
            ...(typedPersistedState.activeLocale && {
              activeLocale: typedPersistedState.activeLocale,
              activeLang:
                getLangByLocale(typedPersistedState.activeLocale) ||
                defaultLang,
            }),
          };
        },
      }
    )
  )
);

// activeLocale is used for simplicity purposes:
// set it and the store automatically sets active lang
useStore.subscribe(
  state => state.activeLocale,
  useStore.getState().setActiveLang
);

const useGlobalSelector = () => useStore(useCallback(state => state, []));

export { useGlobalSelector, useStore as useGlobalStore };
