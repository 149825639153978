import { CSSProperties } from 'react';

const ShapesIcon = ({
  fill,
  style,
}: {
  fill?: string;
  style: CSSProperties;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M9.5 0L4 9H15L9.5 0ZM9.5 3.84L11.43 7H7.56L9.5 3.84ZM15 11C12.51 11 10.5 13.01 10.5 15.5C10.5 17.99 12.51 20 15 20C17.49 20 19.5 17.99 19.5 15.5C19.5 13.01 17.49 11 15 11ZM15 18C13.62 18 12.5 16.88 12.5 15.5C12.5 14.12 13.62 13 15 13C16.38 13 17.5 14.12 17.5 15.5C17.5 16.88 16.38 18 15 18ZM0.5 19.5H8.5V11.5H0.5V19.5ZM2.5 13.5H6.5V17.5H2.5V13.5Z"
      fill={fill}
    />
  </svg>
);

export default ShapesIcon;
