import { IntlShape } from 'react-intl';
import { authTranslations } from './auth';
import { calendarTranslations } from './calendar';
import { contactTranslations } from './contacts';
import { exportTranslations } from './export';
import { faqTranslations } from './faq';
import { generalTranslations } from './general';
import { profileTranslations } from './profile';
import { seasonConfigurationTranslations } from './season-configuration';
import { usersManagementTranslations } from './users-management';

export const translations = (intl: IntlShape) => ({
  ...generalTranslations(intl),
  ...authTranslations(intl),
  ...usersManagementTranslations(intl),
  ...seasonConfigurationTranslations(intl),
  ...calendarTranslations(intl),
  ...exportTranslations(intl),
  ...profileTranslations(intl),
  ...contactTranslations(intl),
  ...faqTranslations(intl),
});
