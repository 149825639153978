import { MenuItem, Stack } from '@mui/material';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ILanguagePopoverProps } from './LanguagePopover.types';
import { Image, MenuPopover } from 'components';
import { IconButtonAnimate } from 'components/animations';
import { allLangs } from 'config';
import { useGlobalStore } from 'stores';
import { Locales } from 'types/intl';

const { setActiveLocale } = useGlobalStore.getState();

const LanguagePopover = ({
  popoverProps,
  containerSx,
  arrow,
  hideLabel,
}: ILanguagePopoverProps) => {
  const activeLang = useGlobalStore(state => state.activeLang, shallow);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) =>
    setOpenPopover(event.currentTarget);

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLocale: Locales) => {
    setActiveLocale(newLocale);
    handleClosePopover();
  };

  return (
    <Box sx={containerSx}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!hideLabel && (
          <Typography variant="button">{activeLang.languageLabel}</Typography>
        )}
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            width: 40,
            height: 40,
            ...(openPopover && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <Image disabledEffect src={activeLang.icon} alt={activeLang.label} />
        </IconButtonAnimate>
      </Box>

      <MenuPopover
        onClose={handleClosePopover}
        sx={{ width: 180 }}
        {...popoverProps}
        anchorEl={openPopover}
        open={!!openPopover}
        arrow={arrow}
      >
        <Stack spacing={0.75}>
          {allLangs.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === activeLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </Box>
  );
};

export { LanguagePopover };
