import { Image } from '@react-pdf/renderer';
import { styles } from './PdfLogo.styles';
import { IPdfLogoProps } from './PdfLogo.types';
import Logo2x from 'assets/logos/logo2x.png';

const PdfLogo = ({ style, fixed }: IPdfLogoProps) => (
  <Image style={style || styles.root} src={Logo2x} fixed={fixed} />
);

export { PdfLogo };
