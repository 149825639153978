import { Outlet } from 'react-router-dom';

import { HeaderStyle } from './LogoOnlyLayout.styles';
import { Logo } from 'components';

import * as PATHS from 'constants/paths';

const LogoOnlyLayout = () => (
  <>
    <HeaderStyle>
      <Logo sx={{ width: '150px' }} link={PATHS.ROOT} />
    </HeaderStyle>
    <Outlet />
  </>
);

export { LogoOnlyLayout };
