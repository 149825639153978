import { IntlShape } from 'react-intl';

export const generalTranslations = (intl: IntlShape) => ({
  general: {
    navbar: {
      home: intl.formatMessage({
        defaultMessage: 'Home',
        id: 'general.navbar.home',
      }),
      calendar: intl.formatMessage({
        defaultMessage: 'Calendario',
        id: 'general.navbar.calendar',
      }),
      usersManagement: intl.formatMessage({
        defaultMessage: 'Gestione Utenti',
        id: 'general.navbar.usersManagement',
      }),
      config: intl.formatMessage({
        defaultMessage: 'Configuratore Stagione',
        id: 'general.navbar.config',
      }),
      profile: intl.formatMessage({
        defaultMessage: 'Profilo',
        id: 'general.navbar.profile',
      }),
      contact: intl.formatMessage({
        defaultMessage: 'Contatti',
        id: 'general.navbar.contact',
      }),
      archive: intl.formatMessage({
        defaultMessage: 'Archivio Stagioni',
        id: 'general.navbar.archive',
      }),
      logout: intl.formatMessage({
        defaultMessage: 'Logout',
        id: 'general.navbar.logout',
      }),
    },
    home: {
      title: intl.formatMessage({
        defaultMessage: 'Benvenuto, in A.C. Milan Accrediti Stampa',
        id: 'general.home.title',
      }),
    },
    time: {
      hours: intl.formatMessage({
        defaultMessage: 'Ore',
        id: 'general.time.hours',
      }),
      minutes: intl.formatMessage({
        defaultMessage: 'Minuti',
        id: 'general.time.minutes',
      }),
      seconds: intl.formatMessage({
        defaultMessage: 'Secondi',
        id: 'general.time.seconds',
      }),
      today: intl.formatMessage({
        defaultMessage: 'Oggi',
        id: 'general.time.today',
      }),
      time: intl.formatMessage({
        defaultMessage: 'Orario',
        id: 'general.time.time',
      }),
    },
    competitions: {
      CAMPIONATO: intl.formatMessage({
        defaultMessage: 'Campionato',
        id: 'general.competitions.CAMPIONATO',
      }),
      COPPA_ITALIA: intl.formatMessage({
        defaultMessage: 'Coppa Italia',
        id: 'general.competitions.COPPA_ITALIA',
      }),
      CHAMPIONS_LEAGUE: intl.formatMessage({
        defaultMessage: 'Champions League',
        id: 'general.competitions.CHAMPIONS_LEAGUE',
      }),
      EUROPA_LEAGUE: intl.formatMessage({
        defaultMessage: 'Europa League',
        id: 'general.competitions.EUROPA_LEAGUE',
      }),
      SUPERCOPPA_ITALIANA: intl.formatMessage({
        defaultMessage: 'Supercoppa Italiana',
        id: 'general.competitions.SUPERCOPPA_ITALIANA',
      }),
      FRIENDLY: intl.formatMessage({
        defaultMessage: 'Amichevole',
        id: 'general.competitions.FRIENDLY',
      }),
      CONFERENCE_LEAGUE: intl.formatMessage({
        defaultMessage: 'Conference League',
        id: 'general.competitions.CONFERENCE_LEAGUE',
      }),
    },
    sampleSection: {
      sampleMessage: intl.formatMessage({
        defaultMessage: 'Testo di esempio',
        id: 'general.sampleSection.sampleMessage',
      }),
    },
    user: {
      status: {
        approved: intl.formatMessage({
          defaultMessage: 'Approvato',
          id: 'general.user.status approved',
        }),
        tobeApproved: intl.formatMessage({
          defaultMessage: 'Pending',
          id: 'general.user.status tobeApproved',
        }),
        tobeConfirmed: intl.formatMessage({
          defaultMessage: 'Da approvare',
          id: 'general.user.status tobeConfirmed',
        }),
        revoked: intl.formatMessage({
          defaultMessage: 'Revocato',
          id: 'general.user.status revoked',
        }),
        new: intl.formatMessage({
          defaultMessage: 'Utente interno',
          id: 'general.user.status new',
        }),
      },
      categories: {
        ITALIAN_PHOTOGRAPHER: intl.formatMessage({
          defaultMessage: 'Italian Photographer',
          id: 'general.user.categories.ITALIAN_PHOTOGRAPHER',
        }),
        FOREIGN_PHOTOGRAPHER: intl.formatMessage({
          defaultMessage: 'Foreign Photographer',
          id: 'general.user.categories.FOREIGN_PHOTOGRAPHER',
        }),
        ITALIAN_PRESS: intl.formatMessage({
          defaultMessage: 'Italian Press',
          id: 'general.user.categories.ITALIAN_PRESS',
        }),
        FOREIGN_PRESS: intl.formatMessage({
          defaultMessage: 'Foreign Press',
          id: 'general.user.categories.FOREIGN_PRESS',
        }),
        OBSERVER_STAND: intl.formatMessage({
          defaultMessage: 'Observer Stand',
          id: 'general.user.categories.OBSERVER_STAND',
        }),
        GUEST_PRESS_OFFICE: intl.formatMessage({
          defaultMessage: 'Ufficio Stampa Ospite',
          id: 'general.user.categories.GUEST_PRESS_OFFICE',
        }),
        WEB_MASTER: intl.formatMessage({
          defaultMessage: 'Website',
          id: 'general.user.categories.WEB_MASTER',
        }),
        RADIO: intl.formatMessage({
          defaultMessage: 'Radio',
          id: 'general.user.categories.RADIO',
        }),
        TV: intl.formatMessage({
          defaultMessage: 'TV',
          id: 'general.user.categories.TV',
        }),
        TV_TECHNICIAN: intl.formatMessage({
          defaultMessage: 'TV Technician',
          id: 'general.user.categories.TV_TECHNICIAN',
        }),
      },
      roles: {
        Admin: intl.formatMessage({
          defaultMessage: 'Admin',
          id: 'general.user.roles.Admin',
        }),
        Journalist: intl.formatMessage({
          defaultMessage: 'Giornalista',
          id: 'general.user.roles.Journalist',
        }),
        Photographer: intl.formatMessage({
          defaultMessage: 'Fotografo',
          id: 'general.user.roles.Photographer',
        }),
        Secreteriat: intl.formatMessage({
          defaultMessage: 'Segreteria',
          id: 'general.user.roles.Secreteriat',
        }),
      },
    },
    errors: {
      fieldRequired: intl.formatMessage({
        defaultMessage: 'Campo richiesto',
        id: 'general.errors.fieldRequired',
      }),
      emailNotValid: intl.formatMessage({
        defaultMessage: 'Indirizzo email non valido',
        id: 'general.errors.emailNotValid',
      }),
      somethingWentWrong: intl.formatMessage({
        defaultMessage:
          'Qualcosa è andato storto, per favore riprova più tardi.',
        id: 'general.errors.somethingWentWrong',
      }),
      userAlreadyExists: intl.formatMessage({
        defaultMessage: 'Utente già registrato con questa email',
        id: 'general.errors.userAlreadyExists',
      }),
      oldPassWrong: intl.formatMessage({
        defaultMessage: 'Password attuale errata',
        id: 'general.errors.oldPassWrong',
      }),
      invalidDate: intl.formatMessage({
        defaultMessage: 'Data non valida',
        id: 'general.errors.invalidDate',
      }),
      invalidTimeFormat: intl.formatMessage({
        defaultMessage: 'Formato ora non valido',
        id: 'general.errors.invalidTimeFormat',
      }),
      documentsOrPressRequired: intl.formatMessage({
        defaultMessage:
          'Numero di tessera oppure lettere di certificazione richieste',
        id: 'general.errors.documentsOrPressRequired',
      }),
      permissionDenied: intl.formatMessage({
        defaultMessage: 'Permesso negato',
        id: 'general.errors.permissionDenied',
      }),
    },
    form: {
      search: intl.formatMessage({
        defaultMessage: 'Cerca',
        id: 'general.form.search',
      }),
      autocomplete: {
        noOptions: intl.formatMessage({
          defaultMessage: 'Nessuna Opzione',
          id: 'general.form.autoComplete.noOptions',
        }),
      },
      privacy: intl.formatMessage({
        defaultMessage: `Dichiaro di aver letto l'informativa alla privacy e di accettare 
        i Terms&Condition`,
        id: 'general.form.privacy',
      }),
      upload: {
        dropFileOrClick: intl.formatMessage({
          defaultMessage: 'Trascina i file qui o fai click su',
          id: 'general.form.upload.dropFileOrClick',
        }),
        browse: intl.formatMessage({
          defaultMessage: 'sfoglia',
          id: 'general.form.upload.browse',
        }),
        onYourMachine: intl.formatMessage({
          defaultMessage: 'nel tuo computer',
          id: 'general.form.upload.onYourMachine',
        }),
        removeAll: intl.formatMessage({
          defaultMessage: 'Elimina tutti',
          id: 'general.form.upload.removeAll',
        }),
      },
    },
    buttons: {
      ok: intl.formatMessage({
        defaultMessage: 'Ok',
        id: 'general.buttons.ok',
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Annulla',
        id: 'general.buttons.cancel',
      }),
      back: intl.formatMessage({
        defaultMessage: 'Indietro',
        id: 'general.buttons.back',
      }),
      remove: intl.formatMessage({
        defaultMessage: 'Elimina',
        id: 'general.buttons.remove',
      }),
      lightRemove: intl.formatMessage({
        defaultMessage: 'Rimuovi',
        id: 'general.buttons.lightRemove',
      }),
      clearAll: intl.formatMessage({
        defaultMessage: 'Cancella tutto',
        id: 'general.buttons.clearAll',
      }),
      elementsFound: intl.formatMessage({
        defaultMessage: 'Elementi trovati',
        id: 'general.buttons.elementsFound',
      }),
      download: intl.formatMessage({
        defaultMessage: 'Download',
        id: 'general.buttons.download',
      }),
      export: intl.formatMessage({
        defaultMessage: 'Esporta',
        id: 'general.buttons.export',
      }),
      confirm: intl.formatMessage({
        defaultMessage: 'Approva',
        id: 'general.buttons.confirm',
      }),
      reject: intl.formatMessage({
        defaultMessage: 'Rifiuta',
        id: 'general.buttons.reject',
      }),
    },
    dialogs: {
      deleteUser: {
        title: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler eliminare questo utente?',
          id: 'general.dialogs.deleteUser.title',
        }),
        secretariatTitle: intl.formatMessage({
          defaultMessage:
            'Sei sicuro di voler rimuovere dalla lista questo utente?',
          id: 'general.dialogs.deleteUser.secretariatTitle',
        }),
      },
    },
    footer: {
      sempreMilan: intl.formatMessage({
        defaultMessage: '#sempremilan',
        id: 'general.footer.sempreMilan',
      }),
      copyright: intl.formatMessage({
        defaultMessage:
          'Copyright © 2022 ACMilan.com. Tutti i diritti riservati.',
        id: 'general.footer.copyright',
      }),
      notDuplicate: intl.formatMessage({
        defaultMessage: 'Non duplicare o ridistribuire in nessuna forma.',
        id: 'general.footer.notDuplicate',
      }),
      piva: intl.formatMessage({
        defaultMessage: 'Partita IVA: 01073200154',
        id: 'general.footer.piva',
      }),
      siae: intl.formatMessage({
        defaultMessage: 'Licenza SIAE 5330/I/5051',
        id: 'general.footer.siae',
      }),
      socialMedia: intl.formatMessage({
        defaultMessage: 'ac milan sui social media',
        id: 'general.footer.socialMedia',
      }),
      mercato: intl.formatMessage({
        defaultMessage: 'calciomercato',
        id: 'general.footer.mercato',
      }),
      partner: intl.formatMessage({
        defaultMessage: 'partner',
        id: 'general.footer.partner',
      }),
      contacts: intl.formatMessage({
        defaultMessage: 'contatti',
        id: 'general.footer.contacts',
      }),
      privacy: intl.formatMessage({
        defaultMessage: 'privacy',
        id: 'general.footer.privacy',
      }),
      note: intl.formatMessage({
        defaultMessage: 'note legali',
        id: 'general.footer.note',
      }),
      terms: intl.formatMessage({
        defaultMessage: 'termini di utilizzo',
        id: 'general.footer.terms',
      }),
      brand: intl.formatMessage({
        defaultMessage: 'Brand Protection',
        id: 'general.footer.brand',
      }),
      cookie: intl.formatMessage({
        defaultMessage: 'Cookies',
        id: 'general.footer.cookie',
      }),
      acmilancom: intl.formatMessage({
        defaultMessage: 'ACMILAN.COM',
        id: 'general.footer.acmilancom',
      }),
    },
    maintenance: {
      title: intl.formatMessage({
        defaultMessage: 'Sito in Manutenzione',
        id: 'general.maintenance.title',
      }),
    },
  },
});
