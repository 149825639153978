import { pdf } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useAccreditations } from './api';
import { PlaceHoldersPdf, SingleListPdf } from 'components/PdfExports';
import { useSnackbar, useTranslations } from 'hooks';

import { IAccreditationStatus } from 'types/accreditations';
import { UserLitteralCategory } from 'types/user';
import { ExportMapping, exportExcel } from 'utils/excel';
import { downloadBlob } from 'utils/export';
import { getUserTranslatedCategory } from 'utils/user';

export const useAccreditationExports = () => {
  const i18n = useTranslations();
  const { errorSnackbar } = useSnackbar();
  const { getAccreditationsExportData } = useAccreditations();

  const fetchExportData = async (
    id: string | number,
    accreditationStatus: IAccreditationStatus = IAccreditationStatus.APPROVED
  ) =>
    await getAccreditationsExportData({
      useLoader: true,
      accreditationStatus,
      id,
    });

  const downloadLists = async (matchId: string | number) => {
    const res = await fetchExportData(matchId);
    return res
      ? res?.accreditations.length
        ? downloadBlob(
            await pdf(<SingleListPdf exportData={res} i18n={i18n} />).toBlob(),
            i18n.calendar.match.buttons.printLists.filename
          )
        : errorSnackbar(i18n.calendar.match.snackbars.zeroRersults)
      : errorSnackbar(i18n.general.errors.somethingWentWrong);
  };

  const downloadPlaceHolders = async (matchId: string | number) => {
    const res = await fetchExportData(matchId);
    const placeholdersCount = res?.accreditations.filter(a => !!a.seat).length;
    return res
      ? placeholdersCount
        ? downloadBlob(
            await pdf(
              <PlaceHoldersPdf exportData={res} i18n={i18n} />
            ).toBlob(),
            i18n.calendar.match.buttons.printPlaceholders.filename
          )
        : errorSnackbar(i18n.calendar.match.snackbars.zeroRersults)
      : errorSnackbar(i18n.general.errors.somethingWentWrong);
  };

  const downloadSingleList = async (
    id: number | string,
    category: string,
    accreditationStatus: IAccreditationStatus
  ) => {
    const res = await fetchExportData(id, accreditationStatus);
    return res
      ? res?.accreditations.filter(a => a.category === category).length
        ? downloadBlob(
            await pdf(
              <SingleListPdf
                exportData={{
                  ...res,
                  accreditations: res.accreditations.filter(
                    a => a.category === category
                  ),
                }}
                i18n={i18n}
              />
            ).toBlob(),
            (
              i18n.calendar.match.buttons.printSingleList.filenamePrefix +
              getUserTranslatedCategory(i18n, category as UserLitteralCategory)
            ).toLowerCase()
          )
        : errorSnackbar(i18n.calendar.match.snackbars.zeroCategoryResults)
      : errorSnackbar(i18n.general.errors.somethingWentWrong);
  };

  const downloadExcel = async (
    matchId: string | number,
    filterTaxSeal?: boolean
  ) => {
    const res = await fetchExportData(matchId);
    if (res) {
      const parsedAccreditations = res?.accreditations
        ?.filter(acc => !filterTaxSeal || !acc.taxSeal)
        .map(({ organisation, category, user }) => ({
          surname: user.surname?.toUpperCase() || '',
          name: user.name?.toUpperCase() || '',
          birthDate: user.birthDate
            ? format(new Date(user.birthDate), 'dd-MM-yyyy')
            : '',
          city:
            user.council?.name?.toUpperCase() ||
            user.nation?.name?.toUpperCase() ||
            '',
          gender: user.gender?.charAt(0).toUpperCase() || '',
          category: getUserTranslatedCategory(i18n, category).toUpperCase(),
          organisation: organisation.toUpperCase() || '',
          email: user.email?.toUpperCase() || '',
        }));

      const mapping: ExportMapping[] = Object.entries(
        i18n.export.accreditations
      ).map(([k, val]) => ({ dbKey: k, excelKey: val }));

      return parsedAccreditations.length
        ? exportExcel({ data: parsedAccreditations, mapping })
        : errorSnackbar(i18n.calendar.match.snackbars.zeroRersults);
    } else {
      return errorSnackbar(i18n.general.errors.somethingWentWrong);
    }
  };

  return {
    downloadLists,
    downloadPlaceHolders,
    downloadSingleList,
    downloadExcel,
  };
};
