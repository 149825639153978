import { Box } from '@mui/material';
import { ISvgIconStyleProps } from './SvgIconStyle.type';

const SvgIconStyle = ({ src, sx }: ISvgIconStyleProps) => (
  <Box
    component="span"
    sx={{
      width: 24,
      height: 24,
      display: 'inline-block',
      bgcolor: 'currentColor',
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
  />
);

export { SvgIconStyle };
