import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootStyle = styled(Box)({
  right: 0,
  bottom: 0,
  zIndex: 1550,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
});
