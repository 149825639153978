import { IntlShape } from 'react-intl';

export const seasonConfigurationTranslations = (intl: IntlShape) => ({
  seasonConfig: {
    pageTitles: {
      competitions: {
        serieA: intl.formatMessage({
          defaultMessage: 'Serie A',
          id: 'seasonConfig.pageTitles.competitions.serieA',
        }),
        coppaItalia: intl.formatMessage({
          defaultMessage: 'Coppa Italia',
          id: 'seasonConfig.pageTitles.competitions.coppaItalia',
        }),
        championsLeague: intl.formatMessage({
          defaultMessage: 'Champions League',
          id: 'seasonConfig.pageTitles.competitions.championsLeague',
        }),
        europaLeague: intl.formatMessage({
          defaultMessage: 'Europa League',
          id: 'seasonConfig.pageTitles.competitions.europaLeague',
        }),
        superCoppaItaliana: intl.formatMessage({
          defaultMessage: 'Supercoppa Italiana',
          id: 'seasonConfig.pageTitles.competitions.superCoppaItaliana',
        }),
        amichevoli: intl.formatMessage({
          defaultMessage: 'Amichevoli ',
          id: 'seasonConfig.pageTitles.competitions.amichevoli',
        }),
        conferenceLeague: intl.formatMessage({
          defaultMessage: 'Conference League',
          id: 'seasonConfig.pageTitles.competitions.conferenceLeague',
        }),
      },
      calendar: intl.formatMessage({
        defaultMessage: 'Calendario',
        id: 'seasonConfig.pageTitles.calendar',
      }),
      editSeason: intl.formatMessage({
        defaultMessage: 'Modifica Stagione',
        id: 'seasonConfig.pageTitles.editSeason',
      }),
      newSeason: intl.formatMessage({
        defaultMessage: 'Nuova Stagione',
        id: 'seasonConfig.pageTitles.newSeason',
      }),
    },
    tables: {
      competitions: {
        columns: {
          name: intl.formatMessage({
            defaultMessage: 'Partita',
            id: 'seasonConfig.tables.competitions.columns.name',
          }),
          date: intl.formatMessage({
            defaultMessage: 'Data',
            id: 'seasonConfig.tables.competitions.columns.date',
          }),
          hour: intl.formatMessage({
            defaultMessage: 'Orario',
            id: 'seasonConfig.tables.competitions.columns.hour',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Stato     ',
            id: 'seasonConfig.tables.competitions.columns.status',
          }),
        },
      },
    },
    buttons: {
      newMatch: intl.formatMessage({
        defaultMessage: 'Nuova Partita',
        id: 'seasonConfig.buttons.newMatch',
      }),
      newSeason: intl.formatMessage({
        defaultMessage: 'Nuova Stagione',
        id: 'seasonConfig.buttons.newSeason',
      }),
      edit: intl.formatMessage({
        defaultMessage: 'Modifica',
        id: 'seasonConfig.buttons.edit',
      }),
      export: intl.formatMessage({
        defaultMessage: 'Esporta',
        id: 'seasonConfig.buttons.export',
      }),
      remove: intl.formatMessage({
        defaultMessage: 'Elimina',
        id: 'seasonConfig.buttons.remove',
      }),
      confirmed: intl.formatMessage({
        defaultMessage: 'Confermata',
        id: 'seasonConfig.buttons.confirmed',
      }),
      tobeConfirmed: intl.formatMessage({
        defaultMessage: 'Data/Ora Da Confermare',
        id: 'seasonCofnig.buttons.tobeConfirmed',
      }),
    },
    fields: {
      team1Label: intl.formatMessage({
        defaultMessage: 'Squadra 1',
        id: 'seasonConfig.fields.team1Label',
      }),
      team2Label: intl.formatMessage({
        defaultMessage: 'Squadra 2',
        id: 'seasonConfig.fields.team2Label',
      }),
      dateLabel: intl.formatMessage({
        defaultMessage: 'Data',
        id: 'seasonConfig.fields.dateLabel',
      }),
      timeLabel: intl.formatMessage({
        defaultMessage: 'Orario',
        id: 'seasonConfig.fields.timeLabel',
      }),
      stageLabel: intl.formatMessage({
        defaultMessage: 'Giornata/Fase',
        id: 'seasonConfig.fields.stageLabel',
      }),
      stateLabel: intl.formatMessage({
        defaultMessage: 'Stato',
        id: 'seasonConfig.fields.stateLabel',
      }),
      accreditationDateLimit: intl.formatMessage({
        defaultMessage: 'Data chiusura richieste accrediti',
        id: 'seasonConfig.fields.accreditationDateLimit',
      }),
    },
    errors: {
      accLimitGreaterMatch: intl.formatMessage({
        defaultMessage:
          'La data di chiusura delle richieste di accredito non può essere maggiore della data del match',
        id: 'seasonConfig.errors.accLimitGreaterMatch',
      }),
      accLimitLowerToday: intl.formatMessage({
        defaultMessage:
          'La data di chiusura delle richieste di accredito non può essere nel passato',
        id: 'seasonConfig.errors.accLimitLowerToday',
      }),
    },
  },
  seasonDetail: {
    pageTitles: {
      configuredMatches: intl.formatMessage({
        defaultMessage: 'Partite configurate',
        id: 'seasonConfig.pageTitles.configuredMatches',
      }),
      saveSeasonAlertMessage: intl.formatMessage({
        defaultMessage:
          'Inserire un nome per la Nuova Stagione e salvare. Sarà poi possibile configurare le partite della Stagione',
        id: 'seasonConfig.pageTitles.saveSeasonAlertMessage',
      }),
    },
    buttons: {
      publishSeason: intl.formatMessage({
        defaultMessage: 'Pubblica',
        id: 'seasonConfig.buttons.publishSeason',
      }),
      archiveSeason: intl.formatMessage({
        defaultMessage: 'Archivia',
        id: 'seasonConfig.buttons.archiveSeason',
      }),
      saveSeason: intl.formatMessage({
        defaultMessage: 'Salva',
        id: 'seasonConfig.buttons.saveSeason',
      }),
      deleteSeason: intl.formatMessage({
        defaultMessage: 'Elimina',
        id: 'seasonConfig.buttons.deleteSeason',
      }),
      newSeasonBreadcrumbLabel: intl.formatMessage({
        defaultMessage: 'Nuova Stagione',
        id: 'seasonConfig.buttons.newSeasonBreadcrumbLabel',
      }),
      editSeasonBreadcrumbLabel: intl.formatMessage({
        defaultMessage: 'Modifica Stagione',
        id: 'seasonConfig.buttons.editSeasonBreadcrumbLabel',
      }),
    },
    modal: {
      deleteSeasonConfirmMessage: intl.formatMessage({
        defaultMessage: 'Sei sicuro di voler eliminare la stagione?',
        id: 'seasonConfig.modal.deleteSeasonConfirmMessage',
      }),
      deleteSeasonConfirmTitle: intl.formatMessage({
        defaultMessage: 'Attenzione',
        id: 'seasonConfig.modal.deleteSeasonConfirmTitle',
      }),
      publishSeasonConfirmMessage: intl.formatMessage({
        defaultMessage: 'Sei sicuro di voler pubblicare la stagione?',
        id: 'seasonConfig.modal.publishSeasonConfirmMessage',
      }),
      publishSeasonConfirmTitle: intl.formatMessage({
        defaultMessage: 'Attenzione',
        id: 'seasonConfig.modal.publishSeasonConfirmTitle',
      }),
      publishSeasonConfirmButton: intl.formatMessage({
        defaultMessage: 'Conferma',
        id: 'seasonConfig.modal.publishSeasonConfirmButton',
      }),
      exportDelete: {
        title: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler eliminare la stagione',
          id: 'seasonConfig.modal.exportDelete.title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage: 'Risultano già accreditati per questa stagione:',
          id: 'seasonConfig.modal.exportDelete.subtitle',
        }),
        buttons: {
          exportContacts: intl.formatMessage({
            defaultMessage: 'Esporta contatti',
            id: 'seasonConfig.modal.exportDelete.buttons.exportContacts',
          }),
        },
      },
    },
    snackbar: {
      error: {
        seasonFetchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero della stagione',
          id: 'seasonConfig.snackbar.error.seasonFetchError',
        }),
        seasonsFetchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero della lista delle stagioni',
          id: 'seasonConfig.snackbar.error.seasonsFetchError',
        }),
        seasonsDeleteError: intl.formatMessage({
          defaultMessage: "Errore durante l'eliminazione della stagione",
          id: 'seasonConfig.snackbar.error.seasonsDeleteError',
        }),
        seasonsCreateError: intl.formatMessage({
          defaultMessage: 'Errore durante la creazione della stagione',
          id: 'seasonConfig.snackbar.error.seasonsCreateError',
        }),
        seasonsUpdateError: intl.formatMessage({
          defaultMessage: 'Errore durante la modifica della stagione',
          id: 'seasonConfig.snackbar.error.seasonsUpdateError',
        }),
      },
      success: {
        seasonsDeleteSuccess: intl.formatMessage({
          defaultMessage: 'Stagione eliminata correttamente',
          id: 'seasonConfig.snackbar.success.seasonsDeleteSuccess',
        }),
        seasonsCreateSuccess: intl.formatMessage({
          defaultMessage: 'Stagione creata con successo',
          id: 'seasonConfig.snackbar.success.seasonsCreateSuccess',
        }),
        seasonsUpdateSuccess: intl.formatMessage({
          defaultMessage: 'Stagione modificata con successo',
          id: 'seasonConfig.snackbar.success.seasonsUpdateSuccess',
        }),
      },
    },
    statusList: {
      Active: intl.formatMessage({
        defaultMessage: 'Attiva',
        id: 'seasonConfig.statusList.Active',
      }),
      Draft: intl.formatMessage({
        defaultMessage: 'Bozza',
        id: 'seasonConfig.statusList.Draft',
      }),
      Archived: intl.formatMessage({
        defaultMessage: 'Archiviata',
        id: 'seasonConfig.statusList.Archived',
      }),
    },
    fields: {
      nameLabel: intl.formatMessage({
        defaultMessage: 'Nome',
        id: 'seasonConfig.fields.nameLabels',
      }),
    },
  },
  matchList: {
    mapping: {
      matchStatus: {
        confirmed: intl.formatMessage({
          defaultMessage: 'Confermato',
          id: 'seasonConfig.matchList.mapping.matchStatus.confirmed',
        }),
        toconfirm: intl.formatMessage({
          defaultMessage: 'Data e ora da confermare',
          id: 'seasonConfig.matchList.mapping.matchStatus.toconfirm',
        }),
      },
    },
    snackbar: {
      error: {
        createMatchError: intl.formatMessage({
          defaultMessage: 'Errore durante la creazione del match',
          id: 'seasonConfig.snackbar.error.createMatchErrors',
        }),
        deleteMatchError: intl.formatMessage({
          defaultMessage: 'Errore durante la cancellazione del match',
          id: 'seasonConfig.snackbar.error.deleteMatchError',
        }),
        editMatchError: intl.formatMessage({
          defaultMessage: 'Errore durante la modifica del match',
          id: 'seasonConfig.snackbar.error.editMatchError',
        }),
        listMatchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero dei match',
          id: 'seasonConfig.snackbar.error.listMatchError',
        }),
        getMatchError: intl.formatMessage({
          defaultMessage: 'Errore nel recupero del match',
          id: 'seasonConfig.snackbar.error.getMatchError',
        }),
      },
      success: {
        createMatchSuccess: intl.formatMessage({
          defaultMessage: 'Creazione del match avvenuta con successo',
          id: 'seasonConfig.snackbar.success.createMatchSuccess',
        }),
        deleteMatchSuccess: intl.formatMessage({
          defaultMessage: 'Cancellazione del match avvenuta con successo',
          id: 'seasonConfig.snackbar.success.deleteMatchSuccess',
        }),
        editMatchSuccess: intl.formatMessage({
          defaultMessage: 'Modifica del match avvenuta con successo',
          id: 'seasonConfig.snackbar.success.editMatchSuccesss',
        }),
      },
    },
    modal: {
      delete: {
        title: intl.formatMessage({
          defaultMessage: 'Attenzione',
          id: 'match modal delete title',
        }),
        message: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler cancellare la partita?',
          id: 'match modal delete message',
        }),
        confirmButton: intl.formatMessage({
          defaultMessage: 'Elimina',
          id: 'match modal delete button',
        }),
      },
      exportDelete: {
        title: intl.formatMessage({
          defaultMessage: 'Sei sicuro di voler eliminare la partita Milan',
          id: 'seasonConfig.modal.exportDeleteMatch.title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage: 'Risultano già accreditati per questa partita:',
          id: 'seasonConfig.modal.exportDeleteMatch.subtitle',
        }),
        buttons: {
          exportContacts: intl.formatMessage({
            defaultMessage: 'Esporta contatti',
            id: 'seasonConfig.modal.exportDeleteMatch.buttons.exportContacts',
          }),
        },
      },
    },
    breadcrumb: {
      season: intl.formatMessage({
        defaultMessage: 'Stagione',
        id: 'seasonConfig.breadcrumb.season',
      }),
    },
  },
});
