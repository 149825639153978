import { format, getTime, formatDistanceToNow } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useGlobalStore } from 'stores';

// ----------------------------------------------------------------------

export const itTimeZone = 'Europe/Rome';

export function fDate(date: Date | string | number) {
  const locale = useGlobalStore.getState().activeLang.dateFnsValue;
  return format(new Date(date), 'dd MMMM yyyy', { locale });
}

export function fDateTZ(date: Date | string | number) {
  const locale = useGlobalStore.getState().activeLang.dateFnsValue;
  return formatInTimeZone(new Date(date), itTimeZone, 'dd MMMM yyyy', {
    locale,
  });
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateFull(date: Date | string | number) {
  const locale = useGlobalStore.getState().activeLang.dateFnsValue;
  return format(new Date(date), 'EEEE dd MMMM yyyy', { locale });
}

export function fDateFullTZ(date: string) {
  const locale = useGlobalStore.getState().activeLang.dateFnsValue;
  return formatInTimeZone(date, itTimeZone, 'EEEE dd MMMM yyyy', { locale });
}

export function fTime(date: Date | string | number) {
  return format(new Date(date), 'HH:mm');
}
export function fTimeTZ(date: Date | string | number) {
  return formatInTimeZone(new Date(date), itTimeZone, 'HH:mm');
}

export function fTimeToTimeString(time: string) {
  const timeArray = time.split(':');
  const timeBaseDate = new Date();
  timeBaseDate.setHours(Number(timeArray[0]));
  timeBaseDate.setMinutes(Number(timeArray[1]));
  return format(timeBaseDate, 'HH:mm');
}

export function fTimeToTimeDate(time: string, baseDate?: string | Date) {
  const timeArray = time.split(':');
  const timeBaseDate = baseDate ? new Date(baseDate) : new Date();
  timeBaseDate.setHours(Number(timeArray[0]));
  timeBaseDate.setMinutes(Number(timeArray[1]));
  return timeBaseDate;
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
