import { Localization } from '@mui/material/locale';
import { Locale as DateFnsLocale } from 'date-fns';
import { useTranslations } from 'hooks';
export interface ILang {
  label: string;
  languageLabel: string;
  value: Locales;
  systemValue: Localization;
  dateFnsValue: DateFnsLocale;
  icon: string;
}

export enum Locales {
  IT = 'it',
  EN = 'en',
}

/**
 * @deprecated
 * since July 2023
 *
 * for full translations object (return value of useTranslations)
 * use Translations type instead
 */
export interface ICustomi18n {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type Translations = ReturnType<typeof useTranslations>;
