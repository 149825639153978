import { IconHeadingWrapper, IconWrapper } from './IconHeading.styles';
import { IIconHeadingProps } from './IconHeading.types';
import { ColumnElement } from 'components/CardListItem/ColumnElement/ColumnElement';

const IconHeading = ({
  icon,
  iconBgColor,
  title,
  subtitle,
  inverse,
  titleSpacing,
  showDivider = false,
  capitalizeSubtitle,
  wrapperStyle,
}: IIconHeadingProps) => {
  return (
    <IconHeadingWrapper
      inverse={inverse}
      showDivider={showDivider}
      style={wrapperStyle}
    >
      {icon && (
        <IconWrapper
          bgColor={iconBgColor}
          titleSpacing={titleSpacing}
          inverse={inverse}
        >
          {icon}
        </IconWrapper>
      )}
      <ColumnElement
        title={title}
        subtitle={subtitle}
        inverse={inverse}
        isExtra={false}
        capitalizeSubtitle={capitalizeSubtitle}
        className={'column-element'}
      />
    </IconHeadingWrapper>
  );
};

export { IconHeading };
