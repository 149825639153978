import { Grid } from '@mui/material';
import { styled } from '@mui/material';

export const FooterContainer = styled('footer')(({ theme }) => ({
  paddingBlock: 60,
  paddingInline: 30,
  textTransform: 'uppercase',
  backgroundColor: theme.palette.common.black,
  [theme.breakpoints.up('lg')]: {
    paddingBlock: 65,
  },
}));

export const FooterColumn = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  alignItems: 'center',
  position: 'relative',
  '& .footer-logo': {
    width: 65,
    height: 41,
    display: 'inline-block',
  },
});

export const LogoColumn = styled(FooterColumn)(({ theme }) => ({
  gap: 25,
  [theme.breakpoints.up('sm')]: {
    gap: 90,
  },
  [theme.breakpoints.up('lg')]: {
    gap: 20,
  },

  '& .footer-logo': {
    width: 78,
    height: 50,
  },
}));

export const LinkColumn = styled(FooterColumn)(({ theme }) => ({
  gap: 60,
  paddingBlock: 70,
  [theme.breakpoints.up('sm')]: {
    gap: 90,
  },

  [theme.breakpoints.up('lg')]: {
    paddingBlock: 0,
    gap: 60,
    '&::before': {
      content: '""',
      display: 'block',
      width: 3,
      height: 70,
      background: 'white',
      position: 'absolute',
      right: '0',
    },
  },
}));

export const SocialColumn = styled(FooterColumn)(({ theme }) => ({
  gap: 10,
  '& .footer-social-icon': {
    width: 'auto',
    height: 20,
    display: 'block',
  },

  [theme.breakpoints.up('lg')]: {
    '&::before': {
      content: '""',
      display: 'block',
      width: 3,
      height: 70,
      background: 'white',
      position: 'absolute',
      left: '0',
    },
  },
}));
