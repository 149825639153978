import { Box, Link, ListItemText, Typography, Tooltip } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import {
  ListItemStyle as ListItem,
  ListItemTextStyle,
  ListItemIconStyle,
} from './NavItem.styles';

import {
  ArrowIconProps,
  DotIconProps,
  NavItemProps,
  NavItemSubProps,
} from './NavItem.types';
import { Iconify } from 'components';
import { isExternalLink } from 'utils/navigation';

export const NavItemRoot = ({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
}: NavItemProps) => {
  const {
    title,
    path,
    icon,
    info,
    children,
    disabled,
    caption,
    roles,
    onClick,
  } = item;

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItem
        onClick={onOpen}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem
      component={Link}
      onClick={() => onClick?.()}
      href={path}
      target="_blank"
      rel="noopener"
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItem>
  ) : (
    <ListItem
      component={RouterLink}
      onClick={() => onClick?.()}
      to={path}
      activeRoot={active}
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItem>
  );
};

export const NavItemSub = ({
  item,
  open = false,
  active = false,
  onOpen,
}: NavItemSubProps) => {
  const { title, path, info, children, disabled, caption, roles, onClick } =
    item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ''} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem
        onClick={onOpen}
        activeSub={active}
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem
      component={Link}
      onClick={() => onClick?.()}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItem>
  ) : (
    <ListItem
      component={RouterLink}
      onClick={() => onClick?.()}
      to={path}
      activeSub={active}
      subItem
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItem>
  );
};

export const DotIcon = ({ active }: DotIconProps) => (
  <ListItemIconStyle>
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
        transition: theme =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        ...(active && {
          transform: 'scale(2)',
          bgcolor: 'primary.main',
        }),
      }}
    />
  </ListItemIconStyle>
);

export const ArrowIcon = ({ open }: ArrowIconProps) => (
  <Iconify
    icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
    sx={{ width: 16, height: 16, ml: 1 }}
  />
);
