/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ProgressBar } from 'components';
import * as PATHS from 'constants/paths';
import { AuthGuard, GuestGuard } from 'guards';
import { DashboardLayout, DoubleColLayout, LogoOnlyLayout } from 'layouts';
import { MaintenanceStatus } from 'types/common';
import { UserLitteralTypes } from 'types/user';

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<ProgressBar />}>
      <Component {...props} />
    </Suspense>
  );
};

const MAINTENANCE_MODE =
  process.env.REACT_APP_MAINTENANCE_MODE === MaintenanceStatus.ENABLED;

export default function Router() {
  const maintenanceRoutes = [
    {
      path: PATHS.MAINTENANCE,
      element: <DoubleColLayout />,
      children: [
        {
          element: <Maintenance />,
          index: true,
        },
      ],
    },
    { path: '*', element: <Navigate to={PATHS.MAINTENANCE} replace /> },
  ];

  const defaultRoutes = [
    {
      path: PATHS.ROOT,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Home />,
          index: true,
        },
      ],
    },
    {
      path: PATHS.CALENDAR_ROOT,
      element: (
        <AuthGuard roles={[UserLitteralTypes.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Calendar />, index: true },
        {
          path: PATHS.CALENDAR_MATCH_ID,
          element: <CalendarMatch />,
        },
        {
          path: PATHS.CALENDAR_MATCH_ID_MAP,
          element: <CalendarMap />,
        },
        {
          path: PATHS.CALENDAR_CREDIT_MANAGEMENT_ID,
          element: <CalendarCreditManagement />,
        },
        {
          path: PATHS.CALENDAR_CREDIT_MANAGEMENT_IMPORT_RECAP,
          element: <CreditImportRecap />,
        },
        {
          path: PATHS.CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID,
          element: <CalendarCreditRequest />,
        },
        {
          path: PATHS.CALENDAR_CREDIT_MANAGEMENT_REQUEST,
          element: <CalendarCreditRequest />,
        },
        {
          path: PATHS.CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID_MAP,
          element: <CalendarMap />,
        },
      ],
    },
    {
      path: PATHS.AUTH_ROOT,
      element: <DoubleColLayout />,
      children: [
        { element: <Navigate to={PATHS.LOGIN} replace />, index: true },
        {
          path: PATHS.LOGIN,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: PATHS.RESET_PASSWORD,
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: PATHS.REGISTER,
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: PATHS.NEW_PASSWORD, element: <NewPassword /> },
        {
          path: PATHS.PASSWORD_CHANGED,
          element: <PasswordChanged />,
        },
      ],
    },
    {
      path: PATHS.USERS_MANAGEMENT_ROOT,
      element: (
        <AuthGuard roles={[UserLitteralTypes.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UsersManagement />, index: true },
        {
          path: PATHS.USERS_MANAGEMENT_CREATE_ADMIN,
          element: <CreateAdmin />,
        },
        {
          path: PATHS.USERS_MANAGEMENT_EDIT_ADMIN_ID,
          element: <EditAdmin />,
        },
        {
          path: PATHS.USERS_MANAGEMENT_CREATE_JOURNALIST,
          element: <CreateJournalist />,
        },
        {
          path: PATHS.USERS_MANAGEMENT_EDIT_JOURNALIST_ID,
          element: <EditJournalist />,
        },
        {
          path: PATHS.USERS_MANAGEMENT_CREATE_SECRETARIAT,
          element: <CreateSecretariat />,
        },
        {
          path: PATHS.USERS_MANAGEMENT_EDIT_SECRETARIAT_ID,
          element: <EditSecretariat />,
        },
        {
          path: PATHS.USERS_MANAGEMENT_LIST_SECRETARIAT_ID,
          element: <ListSecretariat />,
        },
      ],
    },
    {
      path: PATHS.CONFIG_ROOT,
      element: (
        <AuthGuard roles={[UserLitteralTypes.ADMIN]}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Config />, index: true },
        {
          path: PATHS.CONFIG_SEASON_CREATE,
          element: <Season />,
        },
        {
          path: PATHS.CONFIG_SEASON_ID,
          element: <Season />,
        },
        {
          path: PATHS.CONFIG_COMPETITION_ID,
          element: <Competition />,
        },
        {
          path: PATHS.CONFIG_COMPETITION_NEW_MATCH,
          element: <AddOrEditMatch />,
        },
        {
          path: PATHS.CONFIG_COMPETITION_EDIT_MATCH,
          element: <AddOrEditMatch />,
        },
      ],
    },
    {
      path: PATHS.PROFILE_ROOT,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Profile />, index: true }],
    },
    /**
     * FrontOffice Routing
     */
    {
      path: PATHS.CALENDAR_ROOT_FO,
      element: (
        <AuthGuard
          roles={[
            UserLitteralTypes.JOURNALIST,
            UserLitteralTypes.PHOTOGRAPHER,
            UserLitteralTypes.SECRETERIAT,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Calendar />, index: true },
        {
          path: PATHS.CALENDAR_MATCH_ID,
          element: <CalendarMatch />,
        },
      ],
    },
    {
      path: PATHS.CONTACTS,
      element: (
        <AuthGuard
          roles={[
            UserLitteralTypes.JOURNALIST,
            UserLitteralTypes.PHOTOGRAPHER,
            UserLitteralTypes.SECRETERIAT,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Contacts />, index: true }],
    },
    {
      path: PATHS.FAQ,
      element: (
        <AuthGuard
          roles={[
            UserLitteralTypes.JOURNALIST,
            UserLitteralTypes.PHOTOGRAPHER,
            UserLitteralTypes.SECRETERIAT,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Faq />, index: true }],
    },
    {
      path: PATHS.CALENDAR_FO_CREDIT_MANAGEMENT,
      element: (
        <AuthGuard roles={[UserLitteralTypes.SECRETERIAT]}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <RecapSecreatariat />, index: true }],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/sample" replace />, index: true },
        { path: 'sample', element: <Sample /> },
      ],
    },
    {
      path: PATHS.PERMISSION_DENIED,
      element: <LogoOnlyLayout />,
      children: [{ element: <PermissionDenied />, index: true }],
    },
    { path: PATHS.MAINTENANCE, element: <Navigate to={PATHS.ROOT} replace /> },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(MAINTENANCE_MODE ? maintenanceRoutes : defaultRoutes);
}

// Lazy imports
const Maintenance = Loadable(lazy(() => import('pages/Maintenance')));
const Home = Loadable(lazy(() => import('pages/Home')));
// Auth
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
const Register = Loadable(lazy(() => import('pages/auth/Register')));
const NewPassword = Loadable(lazy(() => import('pages/auth/NewPassword')));
const PasswordChanged = Loadable(
  lazy(() => import('pages/auth/PasswordChanged'))
);
const PermissionDenied = Loadable(
  lazy(() => import('pages/auth/PermissionDenied'))
);
// Calendar
const Calendar = Loadable(lazy(() => import('pages/Calendar')));
const CalendarMatch = Loadable(lazy(() => import('pages/Calendar/Match')));
const CalendarCreditManagement = Loadable(
  lazy(() => import('pages/Calendar/Match/CreditManagement'))
);
const CalendarCreditRequest = Loadable(
  lazy(() => import('pages/Calendar/Match/CreditManagement/CreditRequest'))
);
const CalendarMap = Loadable(
  lazy(
    () =>
      import('pages/Calendar/Match/CreditManagement/CreditRequest/CalendarMap')
  )
);
const CreditImportRecap = Loadable(
  lazy(() => import('pages/Calendar/Match/CreditManagement/CreditImportRecap'))
);
// UserManagement
const UsersManagement = Loadable(lazy(() => import('pages/UsersManagement')));
const CreateAdmin = Loadable(
  lazy(() => import('pages/UsersManagement/Create/CreateAdmin/CreateAdmin'))
);
const EditAdmin = Loadable(
  lazy(() => import('pages/UsersManagement/Edit/EditAdmin/EditAdmin'))
);
const CreateJournalist = Loadable(
  lazy(
    () =>
      import('pages/UsersManagement/Create/CreateJournalist/CreateJournalist')
  )
);
const EditJournalist = Loadable(
  lazy(() => import('pages/UsersManagement/Edit/EditJournalist/EditJournalist'))
);
const CreateSecretariat = Loadable(
  lazy(
    () =>
      import('pages/UsersManagement/Create/CreateSecretariat/CreateSecretariat')
  )
);
const EditSecretariat = Loadable(
  lazy(
    () => import('pages/UsersManagement/Edit/EditSecretariat/EditSecretariat')
  )
);
const ListSecretariat = Loadable(
  lazy(() => import('pages/UsersManagement/ListSecretariat/ListSecretariat'))
);

// Season config
const Config = Loadable(lazy(() => import('pages/Config')));
const Season = Loadable(lazy(() => import('pages/Config/Season')));
const Competition = Loadable(
  lazy(() => import('pages/Config/Season/Competition'))
);
const Profile = Loadable(lazy(() => import('pages/Profile')));

/**
 * Front Office components
 */
const Contacts = Loadable(lazy(() => import('pages/Frontoffice/Contacts')));
const Faq = Loadable(lazy(() => import('pages/Frontoffice/Faq')));
const RecapSecreatariat = Loadable(
  lazy(() => import('pages/Frontoffice/RecapSecretariat'))
);
const NotFound = Loadable(lazy(() => import('pages/Page404')));
const Sample = Loadable(lazy(() => import('pages/Sample')));
const AddOrEditMatch = Loadable(
  lazy(() => import('pages/Config/Season/Competition/AddOrEditMatch'))
);
