export interface IUser {
  id: number;
  azureId: string;
  name: string;
  surname: string;
  email: string;
  type?: UserLitteralTypes;
  organisation?: string;
  category?: UserLitteralCategory;
  createdAt?: string;
  birthDate?: string;
  status?: UserLitteralStatus;
  gender?: UserLitteralGender;
  childrenCount?: number;
  nation?: { name: string; id: number };
  province?: { name: string; id: number };
  council?: { name: string; id: number };
  enabled: boolean;
  uploadedFiles?: string;
  marketingConsent?: boolean;
}
// ! TODO: Check types with BE
export interface IUserFormFilters {
  surname?: string;
  email?: string;
  organisation?: string;
  category?: UserEnumsCategory;
  status?: UserEnumStatus;
  pageSize?: number;
  page?: number;
}

// ! TODO: Check types with BE
export enum UserStatus {
  PENDING,
  APPROVED,
  REVOKED,
}
export enum UserLitteralStatus {
  NEW = 'New',
  TOBEAPPROVED = 'To Be Approved',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REVOKED = 'Revoked',
}
export type UserEnumStatus = `${UserLitteralStatus}`;

// ! TODO: Check types with BE
export interface IUserSecretariatForm {
  name: string;
  surname: string;
  email: string;
  organisation: string;
  sendCredentials?: boolean;
  status?: string | number;
  childrenCount?: string | number;
}

// ! TODO: Check types with BE
export interface IUserJournalistForm {
  name: string;
  surname: string;
  email: string;
  category: string;
  organisation: string;
  pressPassCode: number | string;
  birthDate: string;
  gender: number | string;
  nationId: number | string;
  provinceId?: number | string;
  councilId?: number | string;
  sendCredentials?: boolean;
  status?: string | number;
  type?: string;
}

// ! TODO: Check types with BE
export interface IUserAdminForm {
  name: string;
  surname: string;
  email: string;
  sendCredentials?: boolean;
  status?: UserEnumStatus | string;
}

// ! TODO: Check types with BE
export enum UserCategory {
  ITALIAN_PHOTOGRAPHER,
  FOREIGN_PHOTOGRAPHER,
  ITALIAN_PRESS,
  FOREIGN_PRESS,
  OBSERVER_STAND,
  GUEST_PRESS_OFFICE,
  WEB_MASTER,
  RADIO,
  TV,
  TV_TECHNICIAN,
}

export enum UserLitteralCategory {
  ITALIAN_PHOTOGRAPHER = 'Italian Photographer',
  FOREIGN_PHOTOGRAPHER = 'Foreign Photographer',
  ITALIAN_PRESS = 'Italian Press',
  FOREIGN_PRESS = 'Foreign Press',
  OBSERVER_STAND = 'Observer Stand',
  GUEST_PRESS_OFFICE = 'Ufficio Stampa Ospite',
  WEB_MASTER = 'Web Master',
  RADIO = 'Radio',
  TV = 'TV',
  TV_TECHNICIAN = 'Tecnici TV',
  ALL = 'All',
}

export type UserEnumsCategory = `${UserLitteralCategory}`;

export interface IUserToken {
  accessToken: string;
  refreshToken: string;
  mustChangePassword: boolean;
}

export enum UserGender {
  Male,
  Female,
}

export enum UserLitteralGender {
  Male = 'Male',
  Female = 'Female',
}

export type UserEnumsGender = `${UserLitteralGender}`;

export enum UserType {
  Admin,
  Journalist,
  Photographer,
  Secreteriat,
}

export enum UserLitteralTypes {
  ADMIN = 'Admin',
  JOURNALIST = 'Journalist',
  PHOTOGRAPHER = 'Photographer',
  SECRETERIAT = 'Secreteriat',
}

export type UserEnumsTypes = `${UserLitteralTypes}`;
