import { Icon } from '@iconify/react';

import { Box } from '@mui/material';
import { IIconifyProps } from './Iconify.types';

const Iconify = ({ icon, sx, ...other }: IIconifyProps) => (
  <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />
);

export { Iconify };
