import { FormProvider as Form } from 'react-hook-form';
import { IFormProviderProps } from './FormProvider.types';

const FormProvider = ({ children, onSubmit, methods }: IFormProviderProps) => (
  <Form {...methods}>
    <form onSubmit={onSubmit} noValidate>
      {children}
    </form>
  </Form>
);

export { FormProvider };
