import 'simplebar/src/simplebar.css';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { IntlProvider, HttpProvider } from 'providers';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <HelmetProvider>
    <HttpProvider>
      <IntlProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntlProvider>
    </HttpProvider>
  </HelmetProvider>
);
