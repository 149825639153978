import { Box, Stack, Drawer, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CollapseButton } from '../CollapseButton/CollapseButton';
import { getFrontOfficeConfig, getNavConfig } from './NavConfig';
import { NavSectionVertical } from './NavSection/NavSectionVertical';
import { NavbarAccount } from './NavbarAccount/NavbarAccount';
import { getNavbarPaperStyles, RootStyle } from './NavbarVertical.styles';
import { INavbarVerticalProps } from './NavbarVertical.types';
import { LanguagePopover, Logo, Scrollbar } from 'components';
import { NAVBAR } from 'config';
import * as PATHS from 'constants/paths';
import { useResponsive, useCollapseDrawer, useTranslations } from 'hooks';
import { useIsAdmin } from 'stores';

const NavbarVertical = ({
  isOpenSidebar,
  onCloseSidebar,
}: INavbarVerticalProps) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const i18n = useTranslations();
  const isAdmin = useIsAdmin();
  const isFrontOffice =
    (process.env.REACT_APP_FO_WEB_URL &&
      window.location.href.includes(process.env.REACT_APP_FO_WEB_URL)) ||
    false;

  const isDesktop = useResponsive('up', 'lg');

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo
            iconOnly={isCollapse}
            link={PATHS.ROOT}
            sx={{ width: isCollapse ? '25px' : '150px' }}
            textColor={theme.palette.common.white}
          />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>
      <NavSectionVertical
        navConfig={isAdmin ? getNavConfig(i18n) : getFrontOfficeConfig(i18n)}
        isCollapse={isCollapse}
      />
      <Box sx={{ flexGrow: 1 }} />

      {isFrontOffice && (
        <LanguagePopover
          containerSx={{ p: 2, pl: 4 }}
          arrow="bottom-center"
          popoverProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            transformOrigin: { vertical: 'bottom', horizontal: 'center' },
          }}
        />
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
        height: '100%',
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: 'background.navBar',
              color: theme.palette.common.white,
            },
          }}
          ModalProps={{
            sx: {
              background:
                'linear-gradient(90deg, #161C24 0%, rgba(22, 28, 36, 0.48) 91.29%)',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: getNavbarPaperStyles(isCollapse, collapseHover, isFrontOffice),
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export { NavbarVertical };
