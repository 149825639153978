const BadgeIcon = ({ fillColor = 'none' }: { fillColor?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.9998 9.99996H11.6665V11.25H14.9998V9.99996Z" fill="black" />
      <path d="M14.9998 12.5H11.6665V13.75H14.9998V12.5Z" fill="black" />
      <path
        d="M16.6665 5.83329H12.4998V3.33329C12.4998 2.41663 11.7498 1.66663 10.8332 1.66663H9.1665C8.24984 1.66663 7.49984 2.41663 7.49984 3.33329V5.83329H3.33317C2.4165 5.83329 1.6665 6.58329 1.6665 7.49996V16.6666C1.6665 17.5833 2.4165 18.3333 3.33317 18.3333H16.6665C17.5832 18.3333 18.3332 17.5833 18.3332 16.6666V7.49996C18.3332 6.58329 17.5832 5.83329 16.6665 5.83329ZM9.1665 3.33329H10.8332V7.49996H9.1665V3.33329ZM16.6665 16.6666H3.33317V7.49996H7.49984C7.49984 8.41663 8.24984 9.16663 9.1665 9.16663H10.8332C11.7498 9.16663 12.4998 8.41663 12.4998 7.49996H16.6665V16.6666Z"
        fill="black"
      />
      <path
        d="M7.49984 12.5C8.19019 12.5 8.74984 11.9403 8.74984 11.25C8.74984 10.5596 8.19019 9.99996 7.49984 9.99996C6.80948 9.99996 6.24984 10.5596 6.24984 11.25C6.24984 11.9403 6.80948 12.5 7.49984 12.5Z"
        fill="black"
      />
      <path
        d="M9.23317 13.4833C8.69984 13.25 8.1165 13.125 7.49984 13.125C6.88317 13.125 6.29984 13.25 5.7665 13.4833C5.29984 13.6833 4.99984 14.1333 4.99984 14.6416V15H9.99984V14.6416C9.99984 14.1333 9.69984 13.6833 9.23317 13.4833Z"
        fill="black"
      />
    </svg>
  );
};

export default BadgeIcon;
