import { Navigate } from 'react-router-dom';
import { GuestGuardProps } from './GuestGuard.types';
import * as PATHS from 'constants/paths';
import { useAuthSelector } from 'stores';

const GuestGuard = ({ children }: GuestGuardProps) => {
  const { isAuthenticated } = useAuthSelector();

  if (isAuthenticated) return <Navigate to={PATHS.ROOT} />;

  return <>{children}</>;
};

export { GuestGuard };
