import { List, Box } from '@mui/material';

import { NavListRoot } from '../NavList/NavList';
import { ListSubheaderStyle } from './NavSectionVertical.styles';
import { NavSectionProps } from './NavSectionVertical.types';

export const NavSectionVertical = ({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) => (
  <Box {...other}>
    {navConfig.map(group => (
      <List key={group.subheader} disablePadding sx={{ px: 2 }}>
        {group.subheader && (
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>
        )}
        {group.items.map(list => (
          <NavListRoot
            key={list.title + list.path}
            list={list}
            isCollapse={isCollapse}
          />
        ))}
      </List>
    ))}
  </Box>
);
