import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Progress(theme: Theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
          height: 6,
        },
        bar: {
          borderRadius: 2,
        },
        colorPrimary: {
          backgroundColor: theme.palette.grey[200],
        },
        buffer: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
}
