import { Box, Stack, Toolbar } from '@mui/material';
// Todo: don't know
// import { useNavigate } from 'react-router';
import { RootStyle } from './Header.styles';
import { IDashboardHeaderProps } from './Header.types';
// import { FaqIcon } from 'assets';
import { Iconify, Logo } from 'components';
import { IconButtonAnimate } from 'components/animations';
import { HEADER } from 'config';
// import * as PATHS from 'constants/paths';
import { useResponsive, useOffSetTop } from 'hooks';
import { useIsAdmin } from 'stores';

const DashboardHeader = ({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: IDashboardHeaderProps) => {
  // const navigate = useNavigate();
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const isAdmin = useIsAdmin();

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {isAdmin || window.location.href.includes('calendar')
            ? null // <NotificationsPopover /> // Temporary hide Notification Bell, Wake it up when September Ends 🔔
            : // <IconButtonAnimate size="small" onClick={() => navigate(PATHS.FAQ)}>
              //   <FaqIcon />
              // </IconButtonAnimate>
              null}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
};

export { DashboardHeader };
