import { Outlet } from 'react-router-dom';

import {
  ContentWrapper,
  LeftCol,
  RightCol,
  Wrapper,
} from './DoubleColLayout.styles';

import milanBg from 'assets/images/milanBackground2023.jpg';
import {
  Image,
  LanguagePopover,
  Logo,
  PageFooter,
  Scrollbar,
} from 'components';
import { useResponsive } from 'hooks';

const DoubleColLayout = () => {
  const isFrontOffice =
    process.env.REACT_APP_FO_WEB_URL &&
    window.location.href.includes(process.env.REACT_APP_FO_WEB_URL);
  const mdUp = useResponsive('up', 'md');
  const renderFooter = () => (isFrontOffice ? <PageFooter /> : null);
  return (
    <>
      <Wrapper>
        <LeftCol>
          <Image
            src={milanBg}
            sx={{
              height: '100%',
            }}
          />
        </LeftCol>
        <Scrollbar sx={{ width: '100%' }}>
          <RightCol>
            {isFrontOffice && (
              <LanguagePopover
                containerSx={{
                  position: 'absolute',
                  top: '50px',
                  right: '70px',
                }}
                arrow="top-right"
                popoverProps={{
                  transformOrigin: { vertical: 'top', horizontal: 'right' },
                  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                }}
              />
            )}
            <ContentWrapper>
              <Logo
                sx={{
                  width: mdUp ? 250 : 170,
                  height: 'auto',
                  marginBottom: '50px',
                }}
              />
              <Outlet />
            </ContentWrapper>
          </RightCol>
        </Scrollbar>
      </Wrapper>
      {renderFooter()}
    </>
  );
};

export { DoubleColLayout };
