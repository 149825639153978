import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Raleway',
  fonts: [
    {
      src: require('assets/fonts/Raleway-Regular.ttf'),
    },
    {
      src: require('assets/fonts/Raleway-Bold.ttf'),
      fontWeight: 'bold',
    },
  ],
});

export const globalStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '1.5cm',
    fontFamily: 'Raleway',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
  },
  centeredSection: {
    justifyContent: 'center',
  },
});
