import { Translations } from 'types/intl';
import { UserLitteralCategory } from 'types/user';

const userCategoriesMapping = (i18n: Translations) => [
  {
    id: UserLitteralCategory.ALL,
    name: i18n.usersManagement.tables.users.form.allCategories,
  },
  {
    id: UserLitteralCategory.ITALIAN_PHOTOGRAPHER,
    name: i18n.usersManagement.categories.italianPhoto,
  },
  {
    id: UserLitteralCategory.FOREIGN_PHOTOGRAPHER,
    name: i18n.usersManagement.categories.foreignPhoto,
  },
  {
    id: UserLitteralCategory.ITALIAN_PRESS,
    name: i18n.usersManagement.categories.italianPress,
  },
  {
    id: UserLitteralCategory.FOREIGN_PRESS,
    name: i18n.usersManagement.categories.foreignPress,
  },
  {
    id: UserLitteralCategory.OBSERVER_STAND,
    name: i18n.usersManagement.categories.observerStand,
  },
  {
    id: UserLitteralCategory.GUEST_PRESS_OFFICE,
    name: i18n.usersManagement.categories.organisation,
  },
  {
    id: UserLitteralCategory.WEB_MASTER,
    name: i18n.usersManagement.categories.webMaster,
  },
  {
    id: UserLitteralCategory.RADIO,
    name: i18n.usersManagement.categories.radio,
  },
  {
    id: UserLitteralCategory.TV,
    name: i18n.usersManagement.categories.tv,
  },
  {
    id: UserLitteralCategory.TV_TECHNICIAN,
    name: i18n.usersManagement.categories.techTv,
  },
];

export { userCategoriesMapping };
