import { useFetch } from 'use-http';

import * as API from 'constants/api';
import { useGlobalSelector } from 'stores';
import {
  IHttpHelperExtendedProps,
  IHttpMutateHelperProps,
  IUpdatePasswordHelperProps,
  IHttpNoResponseHelperProps,
} from 'types/httpClient';
import {
  ILoginInput,
  IUpdatePasswordInput,
  ISendCredentialInput,
} from 'types/request';
import { ILoginResponse } from 'types/response';
import { logApiError } from 'utils/api';

const useAuth = () => {
  const { put, post } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();

  const login = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    ILoginResponse,
    ILoginInput
  >): Promise<ILoginResponse | null> => {
    let data: ILoginResponse;
    try {
      useLoader && showLoader();
      data = await post(API.LOGIN, body);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'login');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const sendCredentials = async ({
    useLoader,
    body,
  }: IHttpNoResponseHelperProps<ISendCredentialInput>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await post(`${API.AUTH_ROOT}/${body?.id}/sendCredentials`);
      return true;
    } catch (err) {
      logApiError(err);
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const updatePassword = async ({
    useLoader,
    isFirstChange = false,
    body,
  }: IUpdatePasswordHelperProps<
    void,
    IUpdatePasswordInput
  >): Promise<boolean> => {
    const updateEndpoint = isFirstChange ? API.FIRST_PASSWORD : API.PASSWORD;
    try {
      useLoader && showLoader();
      await put(updateEndpoint, body);
      return true;
    } catch (err) {
      logApiError(err, 'updatePassword');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const forgotPassword = async ({
    useLoader,
    email,
  }: IHttpHelperExtendedProps<void>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await post(API.FORGOT_PASSWORD, { email });
      return true;
    } catch (err) {
      logApiError(err, 'forgotPassword');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const updateForgotPassword = async ({
    useLoader,
    code,
    body,
  }: IUpdatePasswordHelperProps<
    void,
    IUpdatePasswordInput
  >): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await put(`${API.FORGOT_PASSWORD}?code=${code}`, body);
      return true;
    } catch (err) {
      logApiError(err, 'updateforgotPassword');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    login,
    sendCredentials,
    updatePassword,
    forgotPassword,
    updateForgotPassword,
  };
};

export { useAuth };
