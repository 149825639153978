import { SvgIconStyle } from 'components';
import * as PATHS from 'constants/paths';
import { useAuthSelector } from 'stores';
import { ICustomi18n } from 'types/intl';

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  home: getIcon('home'),
  calendar: getIcon('calendar'),
  users: getIcon('users'),
  config: getIcon('ball'),
  profile: getIcon('profile'),
  mail: getIcon('mail'),
  logout: getIcon('logout'),
  faq: getIcon('help_outline'),
};

export const getNavConfig = (i18n: ICustomi18n) => {
  const translations = i18n.general.navbar;
  const { handleLogout } = useAuthSelector();
  return [
    {
      subheader: '',
      items: [
        { title: translations.home, path: PATHS.ROOT, icon: ICONS.home },
        {
          title: translations.calendar,
          path: PATHS.CALENDAR_ROOT,
          icon: ICONS.calendar,
        },
        {
          title: translations.usersManagement,
          path: PATHS.USERS_MANAGEMENT_ROOT,
          icon: ICONS.users,
        },
        {
          title: translations.config,
          path: PATHS.CONFIG_ROOT,
          icon: ICONS.config,
        },
        {
          title: translations.profile,
          path: PATHS.PROFILE_ROOT,
          icon: ICONS.profile,
        },
        {
          title: translations.logout,
          path: '',
          icon: ICONS.logout,
          onClick: handleLogout,
        },
      ],
    },
  ];
};

export const getFrontOfficeConfig = (i18n: ICustomi18n) => {
  const translations = i18n.general.navbar;
  const { handleLogout } = useAuthSelector();
  return [
    {
      subheader: '',
      items: [
        {
          title: translations.calendar,
          path: PATHS.CALENDAR_ROOT_FO,
          icon: ICONS.calendar,
        },
        {
          title: translations.profile,
          path: PATHS.PROFILE_ROOT,
          icon: ICONS.profile,
        },
        {
          title: translations.contact,
          path: PATHS.CONTACTS,
          icon: ICONS.mail,
        },
        {
          title: i18n.faq.title.toUpperCase(),
          path: PATHS.FAQ,
          icon: ICONS.faq,
        },
        {
          title: translations.logout,
          path: '',
          icon: ICONS.logout,
          onClick: handleLogout,
        },
      ],
    },
  ];
};
