import { GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from 'components';

import { SortingCode } from 'types/httpClient';

interface IUseTableProps {
  defaultPageSize?: number;
}

export const useTable = <T>({
  defaultPageSize = DEFAULT_TABLE_PAGE_SIZE,
}: IUseTableProps = {}) => {
  const [filter, setFilter] = useState<T | { [key: string]: number | string }>({
    pageSize: defaultPageSize,
    page: 1,
  });

  const [multipleSelection, setMultipleSelection] =
    useState<GridSelectionModel>([]);
  const [singleSelection, setSingleSelection] = useState<number | undefined>();

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page });
  };
  const handlePageSizeChange = (pageSize: number) => {
    setFilter({ ...filter, pageSize });
  };
  const handleSortModelChange = (filters: GridSortModel) => {
    const filterType = filters[0]?.field;

    setFilter({
      ...filter,
      sortBy: filterType,
      sortOrder: !filters[0]?.sort
        ? SortingCode.ASCENDING
        : filters[0].sort === 'asc'
        ? SortingCode.ASCENDING
        : SortingCode.DESCENDING,
    });
  };

  const handleSelectionModelChange = (ms: GridSelectionModel) =>
    setMultipleSelection(ms);

  return {
    filter,
    setFilter,
    handlePageChange,
    handlePageSizeChange,
    handleSortModelChange,
    handleSelectionModelChange,
    multipleSelection,
    singleSelection,
    setSingleSelection,
  };
};
