import { CSSProperties } from 'react';

const CalendarAltIcon = ({
  fill = 'black',
  style,
}: {
  fill?: string;
  style?: CSSProperties;
}) => (
  <svg
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M17.334 2.74935H16.4173V0.916016H14.584V2.74935H5.41732V0.916016H3.58398V2.74935H2.66732C1.65898 2.74935 0.833984 3.57435 0.833984 4.58268V19.2494C0.833984 20.2577 1.65898 21.0827 2.66732 21.0827H17.334C18.3423 21.0827 19.1673 20.2577 19.1673 19.2494V4.58268C19.1673 3.57435 18.3423 2.74935 17.334 2.74935ZM17.334 19.2494H2.66732V9.16602H17.334V19.2494ZM17.334 7.33268H2.66732V4.58268H17.334V7.33268Z"
      fill={fill}
    />
  </svg>
);

export default CalendarAltIcon;
