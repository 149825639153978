import { GlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SnackbarStyles = () => {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            marginBottom: theme.spacing(1.2),
            marginRight: theme.spacing(1.2),
            minHeight: '56px',
            [theme.breakpoints.up('md')]: {
              minWidth: 320,
            },
          },
          '& .SnackbarItem-variantSuccess': {
            backgroundColor: theme.palette.success.dark,
          },
          '& .SnackbarItem-variantInfo': {
            backgroundColor: theme.palette.info.main,
          },
          '& .SnackbarItem-variantError': {
            backgroundColor: theme.palette.error.main,
          },
          '& .SnackbarItem-message': {
            lineHeight: 2,
          },
          '& .SnackbarItem-contentRoot': {
            padding: '0 20px 0 15px',
          },
        },
      }}
    />
  );
};

export { SnackbarStyles };
