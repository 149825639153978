const FriendlyIcon = ({ fillColor = 'black' }: { fillColor?: string }) => {
  return (
    <svg
      width="32"
      height="21"
      viewBox="0 0 32 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.362954C0.172593 0.0806564 0.433318 0 0.75647 0C10.7522 0.0036662 20.7478 0.0036662 30.7472 0.0036662C31.3311 0.0036662 31.5 0.172311 31.5 0.747905C31.5 7.24808 31.5 13.7519 31.5 20.2521C31.5 20.8277 31.3274 20.9963 30.7472 20.9963C20.7515 20.9963 10.7522 20.9963 0.75647 21C0.433318 21 0.176265 20.9193 0 20.637V0.362954H0ZM1.06493 5.08502C1.18979 5.08502 1.29995 5.08502 1.41012 5.08502C2.80555 5.08502 4.19731 5.08502 5.59274 5.08502C6.13255 5.08502 6.30881 5.261 6.30881 5.80726C6.30881 8.9382 6.30881 12.0728 6.30881 15.2037C6.30881 15.7463 6.12888 15.9186 5.58539 15.9186C4.18996 15.9186 2.7982 15.9186 1.40277 15.9186C1.29261 15.9186 1.18612 15.9186 1.06861 15.9186V19.9038H15.1882V15.2001C12.5919 14.8151 11.0496 12.7217 11.0166 10.5623C10.9872 8.45426 12.445 6.22888 15.1845 5.8036V1.10353H1.06493V5.08502ZM30.4351 1.09619H16.3082V5.79626C18.9485 6.21055 20.3659 8.25995 20.4724 10.2507C20.5312 11.3396 20.2374 12.3368 19.6021 13.224C18.7906 14.3532 17.6779 14.9948 16.3082 15.1927V19.9001H30.424V15.915H30.0642C28.6908 15.915 27.3174 15.915 25.944 15.915C25.3601 15.915 25.1912 15.75 25.1912 15.1707C25.1912 12.0581 25.1912 8.94553 25.1912 5.83293C25.1912 5.24633 25.3601 5.08135 25.9366 5.08135C27.3211 5.08135 28.7055 5.08135 30.0899 5.08135C30.2 5.08135 30.3102 5.08135 30.4351 5.08135V1.09619ZM1.06861 6.15189V14.8444H5.2145V6.15189H1.06861ZM30.4461 6.15555H26.2855V14.8371H30.4461V6.15555ZM15.1955 6.91446C13.4549 7.08677 12.008 8.83554 12.0815 10.6027C12.1659 12.6411 13.8809 14.0232 15.1955 14.0635V6.91446ZM16.3045 14.0929C18.0598 13.8692 19.4405 12.2561 19.4185 10.4817C19.3965 8.68523 17.9974 7.07577 16.3045 6.91812V14.0929Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default FriendlyIcon;
