import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  mailTo: (str: ReactNode) => (
    <a href={`mailTo: ${str}`} style={{ color: '#D50000' }}>
      {str}
    </a>
  ),
  b: (str: ReactNode) => <strong>{str}</strong>,
};

export const authTranslations = (intl: IntlShape) => ({
  auth: {
    login: {
      title: intl.formatMessage({
        defaultMessage: 'Benvenuto',
        id: 'auth.login.title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci sotto le tue credenziali per accedere alla piattaforma di gestione degli accrediti di AC Milan',
        id: 'auth.login.subtitle',
      }),
      buttons: {
        resetPassword: intl.formatMessage({
          defaultMessage: 'Hai dimenticato la password?',
          id: 'auth.login.buttons.resetPassword',
        }),
        signIn: intl.formatMessage({
          defaultMessage: 'Accedi',
          id: 'auth.login.buttons.signIn',
        }),
        register: intl.formatMessage({
          defaultMessage: 'Richiedi iscrizione',
          id: 'auth.login.buttons.register',
        }),
      },
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email',
          id: 'auth.login.fields.email',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password',
          id: 'auth.login.fields.password',
        }),
        rememberMe: intl.formatMessage({
          defaultMessage: 'Ricordami',
          id: 'auth.login.fields.rememberMe',
        }),
      },
      errors: {
        email: intl.formatMessage({
          defaultMessage: 'Inserisci un indirizzo email valido',
          id: 'auth.login.errors.email',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password obbligatoria',
          id: 'auth.login.errors.password',
        }),
      },
      snackbars: {
        invalidCredentials: intl.formatMessage({
          defaultMessage: 'Credenziali non valide',
          id: 'auth.login.snackbars.invalidCredentials',
        }),
      },
    },
    register: {
      title: intl.formatMessage({
        defaultMessage: 'Registrazione',
        id: 'auth.register.title',
      }),
      titleConfirm: intl.formatMessage({
        defaultMessage:
          'Attendi la conferma via email da parte di AC Milan per poter accedere al sistema.',
        id: 'auth.register.titleConfirm',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci tutte le informazioni necessarie per registrarti alla piattaforma di gestione degli accrediti di AC Milan',
        id: 'auth.register.subtitle',
      }),
      subtitleConfirm: intl.formatMessage({
        defaultMessage: 'La tua richiesta è stata inviata correttamente.',
        id: 'auth.register.subtitleConfirm',
      }),
      registerService: intl.formatMessage(
        {
          defaultMessage:
            'Se sei un <b>Giornalista o un Fotografo</b> segui gli step di registrazione compilando il seguente form.',
          id: 'auth.register.registerService',
        },
        { ...htmlParserOptions }
      ),
      registerServiceTwo: intl.formatMessage(
        {
          defaultMessage:
            'Se sei una <b>Segreteria</b> richiedi accesso scrivendo a <mailTo>press@acmilan.com</mailTo> e indicando: il tuo nome e cognome, la tua mail e, per ogni giornalista che vuoi ti sia abilitato: il suo nome, cognome, data e luogo di nascita, indirizzo email, numero di tessera del giornalista.',
          id: 'auth.register.registerServiceTwo',
        },
        { ...htmlParserOptions }
      ),
      fields: {
        name: intl.formatMessage({
          defaultMessage: 'Nome',
          id: 'auth.register.fields.name',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Email',
          id: 'auth.register.fields.email',
        }),
        upload: intl.formatMessage({
          defaultMessage:
            'Se non hai digitato il numero di tessera, carica eventuali lettere di certificazione.',
          id: 'auth.register.fields.upload',
        }),
        privacyOne: intl.formatMessage({
          defaultMessage: "Dichiaro di aver letto l'informativa alla",
          id: 'auth.register.fields.privacyOne',
        }),
        privacyTwo: intl.formatMessage({
          defaultMessage: 'privacy',
          id: 'auth.register.fields.privacyTwo',
        }),
      },
      buttons: {
        register: intl.formatMessage({
          defaultMessage: 'Richiedi iscrizione',
          id: 'auth.register.buttons.register',
        }),
        back: intl.formatMessage({
          defaultMessage: 'Indietro',
          id: 'auth.register.buttons.back',
        }),
        close: intl.formatMessage({
          defaultMessage: 'Chiudi',
          id: 'auth.register.buttons.close',
        }),
      },
    },
    resetPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Recupera password',
        id: 'auth.resetPassword.title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Inserisci il tuo indirizzo di posta elettronica, poi segui le istruzioni che riceverai via email per reimpostare la tua password',
        id: 'auth.resetPassword.subtitle',
      }),
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email',
          id: 'auth.resetPassword.fields.email',
        }),
      },
      buttons: {
        send: intl.formatMessage({
          defaultMessage: 'Invia',
          id: 'auth.resetPassword.fields.send',
        }),
        back: intl.formatMessage({
          defaultMessage: 'Indietro',
          id: 'auth.resetPassword.fields.back',
        }),
      },
      errors: {
        emailNotValid: intl.formatMessage({
          defaultMessage: 'Inserisci un indirizzo Email valido',
          id: 'auth.resetPassword.errors.emailNotValid',
        }),
      },
      snackbars: {
        success: {
          emailSent: intl.formatMessage({
            defaultMessage:
              'Email inviata, controlla la tua casella di posta elettronica',
            id: 'auth.resetPassword.snackbars.success.emailSent',
          }),
        },
        errors: {},
      },
    },
    newPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Imposta password',
        id: 'auth.newPassword.title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Imposta la tua nuova password per accedere al sistema',
        id: 'auth.newPassword.subtitle',
      }),
      requirements: {
        title: intl.formatMessage({
          defaultMessage: 'Requisiti Password',
          id: 'auth.newPassword.requirements.title',
        }),
        length: intl.formatMessage({
          defaultMessage: 'Contenere almeno 8 caratteri',
          id: 'auth.newPassword.requirements.length',
        }),
        spacialChars: intl.formatMessage({
          defaultMessage: 'Contenere caratteri speciali',
          id: 'auth.newPassword.requirements.spacialChars',
        }),
        numbers: intl.formatMessage({
          defaultMessage: 'Contenere numeri',
          id: 'auth.newPassword.requirements.numbers',
        }),
        uppercase: intl.formatMessage({
          defaultMessage: 'Contenere lettere maiuscole',
          id: 'auth.newPassword.requirements.uppercase',
        }),
        lowercase: intl.formatMessage({
          defaultMessage: 'Contenere lettere minuscole',
          id: 'auth.newPassword.requirements.lowercase',
        }),
      },
      fields: {
        password: intl.formatMessage({
          defaultMessage: 'Nuova Password',
          id: 'auth.newPassword.fields.password',
        }),
        confirmPassword: intl.formatMessage({
          defaultMessage: 'Conferma Password',
          id: 'auth.newPassword.fields.confirmPassword',
        }),
        confirmEmail: intl.formatMessage({
          defaultMessage: 'Conferma Email',
          id: 'auth.newPassword.fields.confirmEmail',
        }),
        privacyOne: intl.formatMessage({
          defaultMessage: "Dichiaro di aver letto l'informativa alla",
          id: 'auth.newPassword.fields.privacyOne',
        }),
        privacyTwo: intl.formatMessage({
          defaultMessage: 'privacy',
          id: 'auth.newPassword.fields.privacyTwo',
        }),
      },
      buttons: {
        send: intl.formatMessage({
          defaultMessage: 'Invia',
          id: 'auth.newPassword.buttons.send',
        }),
      },
      errors: {
        passwordMismatch: intl.formatMessage({
          defaultMessage: 'Le password non corrispondono',
          id: 'auth.newPassword.errors.passwordMismatch',
        }),
        emailMismatch: intl.formatMessage({
          defaultMessage: 'Le email non corrispondono',
          id: 'auth.newPassword.errors.emailMismatch',
        }),
        passwordFormatNotValid: intl.formatMessage({
          defaultMessage:
            'La password deve rispettare i requisiti di sicurezza',
          id: 'auth.newPassword.errors.passwordFormatNotValid',
        }),
      },
    },
    passwordChanged: {
      title: intl.formatMessage({
        defaultMessage: 'Password impostata con successo!',
        id: 'auth.passwordChanged.title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Effettua la login per accedere alla piattaforma, utilizzando le tue credenziali',
        id: 'auth.passwordChanged.subtitle',
      }),
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email',
          id: 'auth.passwordChanged.fields.email',
        }),
      },
      buttons: {
        login: intl.formatMessage({
          defaultMessage: 'Login',
          id: 'auth.passwordChanged.buttons.login',
        }),
      },
    },
  },
});
