import { LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Wrapper, ProgressWrapper } from './CardListItem.styles';
import { ICardListItemProps } from './CardListItem.types';
import { ColumnElement } from './ColumnElement/ColumnElement';

import { ArrowRightIcon } from 'assets';
import { IconHeading } from 'components/IconHeading/IconHeading';

const CardListItem = ({
  extraColumns,
  showArrow = false,
  arrowSubtitle,
  progress,
  to,
  iconHeadingWrapperStyle,
  bottomSlot,
  ...iconHeadingProps
}: ICardListItemProps) => {
  const renderExtraColumns = () =>
    extraColumns?.map((col, idx) => <ColumnElement key={idx} {...col} />);

  const renderProgress = () =>
    typeof progress === 'number' && (
      <ProgressWrapper>
        <LinearProgress value={progress} variant="determinate" />
      </ProgressWrapper>
    );

  const renderArrow = () =>
    showArrow && (
      <ColumnElement
        subtitle={arrowSubtitle}
        style={{
          marginInline: 'unset',
          marginLeft: 'auto',
          alignItems: 'flex-end',
        }}
      >
        <ArrowRightIcon />
      </ColumnElement>
    );

  const CardBase = (
    <Wrapper clickable={!!to}>
      <IconHeading
        wrapperStyle={iconHeadingWrapperStyle}
        {...iconHeadingProps}
      />
      {renderExtraColumns()}
      {renderArrow()}
      {renderProgress()}
      {bottomSlot}
    </Wrapper>
  );

  if (!to) return CardBase;

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      {CardBase}
    </Link>
  );
};

export { CardListItem };
