import { useEffect, useState } from 'react';
import { ICountdown } from './useCountdown.types';

const useCountdown = (targetDate: Date): ICountdown => {
  const countDownDate = targetDate.getTime();
  const getCountdown = () => countDownDate - new Date().getTime();

  const [countDown, setCountDown] = useState<number>(getCountdown());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(getCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const daysLeft = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesLeft = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const secondsLeft = Math.floor((countDown % (1000 * 60)) / 1000);

  const isExpired = daysLeft + hoursLeft + minutesLeft + secondsLeft <= 0;

  return { daysLeft, hoursLeft, minutesLeft, secondsLeft, isExpired };
};

export { useCountdown };
