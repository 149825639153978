import { format } from 'date-fns';
import { utils, writeFileXLSX } from 'xlsx';
import { IExportExcel, IWriteExcel } from './export.types';

const writeExcel = ({
  data,
  workSheetColumnNames,
  workSheetName,
}: IWriteExcel) => {
  const workBook = utils.book_new();
  const workSheetData = [workSheetColumnNames, ...data];
  const workSheet = utils.aoa_to_sheet(workSheetData);
  const excelLength = workSheetColumnNames.length;
  const styledColumns = [];
  for (let i = 0; i < excelLength; i++) {
    styledColumns.push({ wch: 20 });
  }
  workSheet['!cols'] = styledColumns;
  utils.book_append_sheet(workBook, workSheet, workSheetName);
  return writeFileXLSX(workBook, `${workSheetName}.xlsx`, {
    bookType: 'xlsx',
    type: 'binary',
  });
};
/**
 * @param  {} data - Data to export
 * @param  {} mapping - Array of objects containing the map of the DB keys and the desired Excel Heading
 * @param  {} dataArrayDivider - If the value is an Array, choose wich divider print in the Excel Cell
 * @param  {} workSheetName - The name of the resulting File
 * @example     exportExcel(
 *                {
 *                  data: [{ name: 'Pier', birthDate: '10-03-1991'}],
 *                  mapping: [{dbKey: 'name', excelKey: 'Nome'}, {dbKey: 'birthDate', excelKey: 'Data di nascita'}]
 *                }
 *              )
 *
 */
const exportExcel = ({
  data,
  mapping,
  dataArrayDivider = '-',
  workSheetName = `export-${format(new Date(), 'dd-MM-yyyy_HH-mm')}`,
}: IExportExcel) => {
  if (data.length) {
    const headers = Object.keys(data[0]).map(k => k);
    const userFriendlyHeaders = headers
      .map(cell => mapping.find(m => m.dbKey === cell)?.excelKey)
      .filter(h => !!h);
    const parsedData = data.map(d =>
      Object.values(d).map(i =>
        i
          ? typeof i === 'object'
            ? i.join(dataArrayDivider)
            : i.toString()
          : ''
      )
    );
    return writeExcel({
      data: parsedData,
      workSheetColumnNames: userFriendlyHeaders,
      workSheetName,
    });
  }
};

export { exportExcel, writeExcel };
