import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  logo: {
    width: '3cm',
    height: 'auto',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  title: {
    fontSize: 20,
  },
  matchName: {
    textTransform: 'uppercase',
    paddingTop: '0.2cm',
    fontSize: 14,
  },
  matchInfo: {
    paddingTop: '0.1cm',
    fontSize: 12,
  },
  categoryName: {
    paddingTop: '0.8cm',
    fontWeight: 'bold',
    fontSize: 14,
  },
  tableHeader: {
    paddingTop: '0.4cm',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid #CC0000',
  },
  columnTitle: {
    paddingTop: '0.8cm',
    fontWeight: 'bold',
    fontSize: 12,
    padding: '0.2cm',
  },
  nameHeader: {
    width: '6cm',
  },
  newspaperHeader: {
    width: '6cm',
  },
  seatHeader: {
    width: '3cm',
    textAlign: 'right',
  },
  tableRow: {
    padding: '0.1cm',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solit #DCDCDC',
  },
  tableCell: {
    padding: '0.1cm',
    fontSize: 9,
  },
  nameCell: {
    textTransform: 'uppercase',
    width: '7cm',
  },
  newspaperCell: {
    textTransform: 'uppercase',
    width: '7cm',
    paddingLeft: '0.2cm',
  },
  seatCell: {
    width: '3cm',
    textAlign: 'right',
  },
});
