import { Dialog, useTheme } from '@mui/material';
import { CloseIconWrapper, ModalBody } from './Modal.styles';
import { ModalProps } from './Modal.types';
import { ModalActions } from './ModalActions/ModalActions';
import { ModalTitle } from './ModalTitle/ModalTitle';
import { CloseIcon } from 'assets';

const Modal = ({
  open,
  children,
  title,
  subtitle,
  showCloseIcon,
  maxWidth = 'md',
  centerContent,
  showActionsDivider,
  stretchButtons,
  fullWidthButtons,
  handleClose,
  buttons,
}: ModalProps) => {
  const theme = useTheme();
  return (
    <Dialog onClose={handleClose} open={!!open} maxWidth={maxWidth}>
      {showCloseIcon && (
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon fill={theme.palette.grey[600]} />
        </CloseIconWrapper>
      )}

      <ModalTitle
        title={title}
        subtitle={subtitle}
        centerContent={centerContent}
      />

      <ModalBody centerContent={centerContent}>{children}</ModalBody>

      {buttons?.length && (
        <ModalActions
          showActionsDivider={showActionsDivider}
          handleClose={handleClose}
          buttons={buttons}
          stretchButtons={stretchButtons}
          fullWidthButtons={fullWidthButtons}
        />
      )}
    </Dialog>
  );
};

export { Modal };
