export const AUTH_ROOT = '/auth';

export const LOGIN = AUTH_ROOT + '/login';
export const PASSWORD = AUTH_ROOT + '/password';
export const FIRST_PASSWORD = AUTH_ROOT + '/firstPassword';
export const FORGOT_PASSWORD = AUTH_ROOT + '/forgotPassword';
export const REFRESH_TOKENS = AUTH_ROOT + '/refreshTokens';

export const USERS = '/users';

export const GEO = '/geo';

export const SEASONS = '/seasons';

export const MATCHES = '/matches';

export const ACCREDITATIONS = '/accreditations';
export const ACCREDITATIONS_IMPORT = ACCREDITATIONS + '/import';
export const ACCREDITATIONS_ASSIGNABLE_USERS =
  ACCREDITATIONS + '/assignableUsers';
export const ACCREDITATIONS_ORGANISATIONS =
  ACCREDITATIONS + '/filters/organisations';

export const ACCREDITATIONS_EXPORT = ACCREDITATIONS + '/export';
