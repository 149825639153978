export interface ICustomApiError extends Partial<IErrorBody> {
  statusCode?: number;
  message?: string;
}

export interface IErrorBody {
  section: number;
  code: number;
  key: keyof typeof APIErrors;
}

export enum APIErrors {
  ExpiredToken,
  CodeDoesNotExists,
  CodeExpired,
}
