import { makePath } from 'utils/navigation';

// ROOTs
export const ROOT = '/';
export const HOME = makePath(ROOT, 'home');
export const AUTH_ROOT = makePath(ROOT, 'auth');
export const CALENDAR_ROOT = makePath(ROOT, 'calendar-management');
export const USERS_MANAGEMENT_ROOT = makePath(ROOT, 'users-management');
export const PROFILE_ROOT = makePath(ROOT, 'profile');
export const ARCHIVE_ROOT = makePath(ROOT, 'archive');
export const MAINTENANCE = makePath(ROOT, 'maintenance');

// Front Office Routes
export const CONTACTS = makePath(ROOT, 'contacts');
export const FAQ = makePath(ROOT, 'faq');
export const CALENDAR_ROOT_FO = makePath(ROOT, 'calendar');
export const CALENDAR_MATCH_FO = makePath(CALENDAR_ROOT_FO, '/match');
export const CALENDAR_MATCH_ID_FO = makePath(CALENDAR_MATCH_FO, '/:matchId');
export const CALENDAR_FO_CREDIT_MANAGEMENT = makePath(
  CALENDAR_MATCH_ID_FO,
  '/credit-management'
);

// Auth Routes
export const LOGIN = makePath(AUTH_ROOT, '/login');
export const NEW_PASSWORD = makePath(AUTH_ROOT, '/new-password');
export const RESET_PASSWORD = makePath(AUTH_ROOT, '/reset-password');
export const PASSWORD_CHANGED = makePath(AUTH_ROOT, '/password-changed');
export const PERMISSION_DENIED = makePath(AUTH_ROOT, '/permission-denied');
export const REGISTER = makePath(AUTH_ROOT, '/register');

// User management Routes
export const USERS_MANAGEMENT_CREATE_ADMIN = makePath(
  USERS_MANAGEMENT_ROOT,
  '/create-admin'
);
export const USERS_MANAGEMENT_EDIT_ADMIN_ID = makePath(
  USERS_MANAGEMENT_ROOT,
  '/edit-admin/:id'
);
export const USERS_MANAGEMENT_CREATE_JOURNALIST = makePath(
  USERS_MANAGEMENT_ROOT,
  '/create-journalist'
);
export const USERS_MANAGEMENT_EDIT_JOURNALIST_ID = makePath(
  USERS_MANAGEMENT_ROOT,
  '/edit-journalist/:id'
);
export const USERS_MANAGEMENT_CREATE_SECRETARIAT = makePath(
  USERS_MANAGEMENT_ROOT,
  '/create-secretariat'
);
export const USERS_MANAGEMENT_EDIT_SECRETARIAT_ID = makePath(
  USERS_MANAGEMENT_ROOT,
  '/edit-secretariat/:id'
);
export const USERS_MANAGEMENT_LIST_SECRETARIAT_ID = makePath(
  USERS_MANAGEMENT_ROOT,
  '/list-secretariat/:id'
);

// Season config Routes
export const CONFIG_ROOT = makePath(ROOT, 'season-config');
export const CONFIG_SEASON_ID = makePath(ROOT, 'season-config/:seasonId');
export const CONFIG_COMPETITION_ID = makePath(
  CONFIG_SEASON_ID,
  '/:competitionSlug'
);
export const CONFIG_SEASON_CREATE = makePath(ROOT, 'season-config/create');
export const CONFIG_COMPETITION_NEW_MATCH = makePath(
  CONFIG_COMPETITION_ID,
  '/create'
);
export const CONFIG_COMPETITION_EDIT_MATCH = makePath(
  CONFIG_COMPETITION_ID,
  '/:matchId'
);

// Calendar Routes
export const CALENDAR_MATCH = makePath(CALENDAR_ROOT, '/match');
export const CALENDAR_MATCH_ID = makePath(CALENDAR_MATCH, '/:matchId');
export const CALENDAR_MATCH_ID_MAP = makePath(CALENDAR_MATCH_ID, '/map');
export const CALENDAR_CREDIT_MANAGEMENT_ID = makePath(
  CALENDAR_MATCH_ID,
  '/credit-management'
);
export const CALENDAR_CREDIT_MANAGEMENT_IMPORT_RECAP = makePath(
  CALENDAR_CREDIT_MANAGEMENT_ID,
  '/import-recap'
);
export const CALENDAR_CREDIT_MANAGEMENT_REQUEST = makePath(
  CALENDAR_CREDIT_MANAGEMENT_ID,
  '/create-credit'
);
export const CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID = makePath(
  CALENDAR_CREDIT_MANAGEMENT_ID,
  '/:creditId'
);

export const CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID_MAP = makePath(
  CALENDAR_CREDIT_MANAGEMENT_REQUEST_ID,
  '/map'
);

/**
 * Routes usefull only for Breadcrumbs
 */
export const USERS_MANAGEMENT_EDIT_JOURNALIST = makePath(
  USERS_MANAGEMENT_ROOT,
  '/edit-journalist'
);
export const USERS_MANAGEMENT_EDIT_ADMIN = makePath(
  USERS_MANAGEMENT_ROOT,
  '/edit-admin'
);
export const USERS_MANAGEMENT_EDIT_SECRETARIAT = makePath(
  USERS_MANAGEMENT_ROOT,
  '/edit-secretariat'
);
export const USERS_MANAGEMENT_LIST_SECRETARIAT = makePath(
  USERS_MANAGEMENT_ROOT,
  '/list-secretariat'
);
