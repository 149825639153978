import { LazyMotion } from 'framer-motion';
import { IMotionLazyContainerProps } from './MotionLazyContainer.types.js';

const loadFeatures = () => import('../features').then(res => res.default);

export default function MotionLazyContainer({
  children,
}: IMotionLazyContainerProps) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}
