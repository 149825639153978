const adminDefaultValues = {
  name: '',
  surname: '',
  email: '',
  status: '',
};

const secretariatDefaultValues = {
  name: '',
  surname: '',
  email: '',
  organisation: '',
  status: '',
  childrenCount: '',
};

const journalistDefaultValues = {
  name: '',
  surname: '',
  email: '',
  organisation: '',
  category: '',
  pressPassCode: '',
  birthDate: '',
  gender: '',
  nationId: '',
  provinceId: '',
  councilId: '',
  status: '',
  sendCredentials: false,
  type: '',
};

const ItalyNationId = 110;

export {
  adminDefaultValues,
  journalistDefaultValues,
  secretariatDefaultValues,
  ItalyNationId,
};
