import useFetch from 'use-http';
import { USERS } from 'constants/api';
import { useGlobalSelector } from 'stores';
import {
  IHttpHelperProps,
  IHttpHelperExtendedProps,
  IHttpMutateHelperProps,
  IUsersFilters,
  IHttpNoResponseHelperProps,
} from 'types/httpClient';
import { IUserInput } from 'types/request';
import { IUsersResponse } from 'types/response';
import { IUser } from 'types/user';
import { logApiError } from 'utils/api';
import { createFiltersQuery } from 'utils/data';

interface UserAvailableChildrenFilter {
  query?: string;
}

const useUsers = () => {
  const { get, post, put, del } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const listUsers = async ({
    useLoader,
    setData,
    body = {},
  }: IHttpMutateHelperProps<
    IUsersResponse,
    IUsersFilters
  >): Promise<IUsersResponse | void> => {
    let data: IUsersResponse;
    try {
      useLoader && showLoader();
      const params = body ? createFiltersQuery(body) : '';
      data = await get(USERS + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-Users');
    } finally {
      useLoader && hideLoader();
    }
  };

  const getUser = async ({
    useLoader,
    setData,
    id,
  }: IHttpHelperExtendedProps<IUser>): Promise<IUser | void> => {
    let data: IUser;
    try {
      useLoader && showLoader();
      data = await get(`${USERS}/${id}`);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-user');
    } finally {
      useLoader && hideLoader();
    }
  };

  const getCurrentUser = async ({
    useLoader,
    setData,
  }: IHttpHelperProps<IUser> = {}): Promise<IUser | boolean> => {
    try {
      useLoader && showLoader();
      const data = await get(`${USERS}/me`);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-current-user');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const updateCurrentUser = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<IUser, IUserInput>): Promise<IUser | boolean> => {
    try {
      useLoader && showLoader();
      const data = await put(`${USERS}/me`, body);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'update-current-user');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const createUser = async ({
    useLoader,
    body,
  }: IHttpMutateHelperProps<IUser, IUserInput>): Promise<IUser | boolean> => {
    try {
      useLoader && showLoader();
      const response = await post(USERS, body);
      return response;
    } catch (err) {
      logApiError(err, 'create-User');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const registerUser = async ({
    useLoader,
    body,
  }: IHttpMutateHelperProps<IUser, IUserInput>): Promise<
    IUser | { code: number; key: string; section: number }
  > => {
    try {
      useLoader && showLoader();
      const response = await post(`${USERS}/register`, body);
      return response;
    } catch (err) {
      logApiError(err, 'register-User');
      return err;
    } finally {
      useLoader && hideLoader();
    }
  };

  const deleteUser = async ({
    useLoader,
    body,
  }: IHttpNoResponseHelperProps<{
    id: number;
  }>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await del(`${USERS}/${body?.id}`);
      return true;
    } catch (err) {
      logApiError(err, 'delete-user');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const createSecreatariatChild = async ({
    useLoader,
    id,
    body,
  }: IHttpMutateHelperProps<
    { id: number; parentId: number; childId: number },
    { childId: number }
  >): Promise<{ id: number; parentId: number; childId: number } | boolean> => {
    try {
      useLoader && showLoader();
      const response = await post(`${USERS}/${id}/child`, body);
      return response;
    } catch (err) {
      logApiError(err, 'create-Secreatariat-child');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const deleteSecreatariatChild = async ({
    useLoader,
    body,
  }: IHttpNoResponseHelperProps<{
    childId: number;
    id: number;
  }>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      const params = { childId: body?.childId };
      await del(`${USERS}/${body?.id}/child`, params);
      return true;
    } catch (err) {
      logApiError(err, 'delete-Secreatariat-child');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const updateUser = async ({
    useLoader,
    id,
    body,
  }: IHttpMutateHelperProps<IUser, IUserInput>): Promise<IUser | boolean> => {
    try {
      useLoader && showLoader();
      const response = await put(`${USERS}/${id}`, body);
      return response;
    } catch (err) {
      logApiError(err, 'update-User');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const listUserChildren = async ({
    useLoader,
    setData,
    id,
    body = {},
  }: IHttpMutateHelperProps<
    IUsersResponse,
    IUsersFilters
  >): Promise<IUsersResponse | void> => {
    let data: IUsersResponse;
    try {
      useLoader && showLoader();
      const params = body ? createFiltersQuery(body) : '';
      data = await get(`${USERS}/${id}/children` + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-User-Children');
    } finally {
      useLoader && hideLoader();
    }
  };

  const listUserAvailableChildren = async ({
    useLoader,
    setData,
    id,
    body = { query: '' },
  }: IHttpMutateHelperProps<IUser[], UserAvailableChildrenFilter>): Promise<
    IUser[] | boolean
  > => {
    let data: IUser[];
    try {
      useLoader && showLoader();
      const params = body && body.query ? `?query=${body.query}` : '';
      data = await get(`${USERS}/${id}/children` + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-User-Available-Children');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    getUser,
    getCurrentUser,
    listUsers,
    listUserChildren,
    listUserAvailableChildren,
    createUser,
    createSecreatariatChild,
    deleteSecreatariatChild,
    updateUser,
    deleteUser,
    registerUser,
    updateCurrentUser,
  };
};

export { useUsers };
