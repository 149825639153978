import useFetch from 'use-http';
import { GEO } from 'constants/api';
import { useGlobalSelector } from 'stores';
import { IGeo } from 'types/geo';
import { IHttpMutateHelperProps } from 'types/httpClient';
import { logApiError } from 'utils/api';
interface GeoFilter {
  parentId?: number;
  context: 'nations' | 'provinces' | 'councils';
}
const useGeo = () => {
  const { get } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const listGeos = async ({
    useLoader,
    setData,
    body = { parentId: undefined, context: 'nations' },
  }: IHttpMutateHelperProps<IGeo[], GeoFilter>): Promise<IGeo[] | void> => {
    let data: IGeo[];
    try {
      useLoader && showLoader();
      const params = body && body.parentId ? `?parentId=${body.parentId}` : '';
      data = await get(`${GEO}/${body?.context}` + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, `list-${body?.context}`);
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    listGeos,
  };
};
export { useGeo };
