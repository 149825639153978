import useCustomIntl from 'hooks/useCustomIntl';
import { translations } from 'i18n/translations';

const useTranslations = () => {
  const intl = useCustomIntl();
  const i18n = translations(intl);

  return i18n;
};

export { useTranslations };
