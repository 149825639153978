import { useEffect, useMemo, useState } from 'react';
import { useTranslations } from './useTranslations';
import { userCategoriesMapping } from 'constants/user';
import { UserLitteralCategory, UserLitteralTypes, UserType } from 'types/user';

type CategoryOption = { id: UserLitteralCategory; name: string };
type CategoryOptionFilterFn = (option: CategoryOption) => boolean;
type CategoriesFilterFnsMap = {
  [keyof in keyof typeof UserType]?: CategoryOptionFilterFn;
};

const categoriesFilterFnsMap: CategoriesFilterFnsMap = {
  Journalist: o => !o.id.toLowerCase().includes('photographer'),
  Photographer: o => o.id.toLowerCase().includes('photographer'),
};

const filterOptions = (
  categories: CategoryOption[],
  userType?: UserLitteralTypes,
  includeAll = false
): CategoryOption[] => {
  const isJournalist = userType === UserLitteralTypes.JOURNALIST;
  const isPhotographer = userType === UserLitteralTypes.PHOTOGRAPHER;
  const hasToBeFiltered = isJournalist || isPhotographer;

  const baseOptions = includeAll
    ? categories
    : categories.filter(c => c.id !== UserLitteralCategory.ALL);

  const nextOptions =
    hasToBeFiltered && userType
      ? baseOptions.filter(c => categoriesFilterFnsMap[userType]?.(c))
      : baseOptions;

  return nextOptions;
};

export const useUserCategories = (
  userType?: UserLitteralTypes,
  includeAll?: boolean
) => {
  const translations = useTranslations();
  const categories = useMemo(() => userCategoriesMapping(translations), []);

  const [availableCategories, setAvailableCategories] =
    useState<CategoryOption[]>(categories);

  useEffect(() => {
    const nextOptions = filterOptions(categories, userType, includeAll);
    setAvailableCategories(nextOptions);
  }, [userType, includeAll, categories]);

  return availableCategories;
};

export { filterOptions as filterUserCategories };
