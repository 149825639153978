import { IntlShape } from 'react-intl';

export const usersManagementTranslations = (intl: IntlShape) => ({
  usersManagement: {
    configuration: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Utenti',
        id: 'usersManagement.configuration.title',
      }),
      filterTitle: intl.formatMessage({
        defaultMessage: 'Filtri',
        id: 'usersManagement.configuration.filterTitle',
      }),
      adminCreateTitle: intl.formatMessage({
        defaultMessage: 'Crea nuovo admin',
        id: 'usersManagement.configuration.adminCreateTitle',
      }),
      journalistCreateTitle: intl.formatMessage({
        defaultMessage: 'Crea nuovo giornalista/fotografo',
        id: 'usersManagemenet.configuration.journalistCreateTitle',
      }),
      secretariatCreateTitle: intl.formatMessage({
        defaultMessage: 'Crea nuova segreteria',
        id: 'usersManagement.configuration.secretariatCreateTitle',
      }),
      adminEditTitle: intl.formatMessage({
        defaultMessage: 'Modifica Admin',
        id: 'usersManagement.configuration.adminEditTitle',
      }),
      secretariatEditTitle: intl.formatMessage({
        defaultMessage: 'Modifica Segreteria',
        id: 'usersManagement.configuration.secretariatEditTitle',
      }),
      secretariatListTitle: intl.formatMessage({
        defaultMessage: 'Liste Segreterie',
        id: 'usersManagement.configuration.secretariatListTitle',
      }),
      journalistEditTitle: intl.formatMessage({
        defaultMessage: 'Modifica giornalista/fotografo',
        id: 'usersManagement.configuration.journalistEditTitle',
      }),
      documentsTitle: intl.formatMessage({
        defaultMessage: 'Documenti Aggiuntivi',
        id: 'usersManagement.configuration.documentsTitle',
      }),
      pendingTitle: intl.formatMessage({
        defaultMessage: 'In attesa di impostazione password',
        id: 'usersManagement.configuration.pendingTitle',
      }),
      subtitles: {
        sendCredentials: intl.formatMessage({
          defaultMessage: 'Invia le credenziali di accesso al frontoffice',
          id: 'usersManagement.configuration.sendCredentials',
        }),
        sendAgainCredentials: intl.formatMessage({
          defaultMessage: 'Invia di nuovo le credenziali',
          id: 'usersManagement.configuration.sendAgainCredentials',
        }),
        alreadySentCredentials: intl.formatMessage({
          defaultMessage: 'Credenziali di accesso al frontoffice inviate.',
          id: 'usersManagement.configuration.alreadySentCredentials',
        }),
        alreadySentCredentialsAdmin: intl.formatMessage({
          defaultMessage: 'Credenziali di accesso inviate.',
          id: 'usersManagement.configuration.alreadySentCredentialsAdmin',
        }),
        admin: intl.formatMessage({
          defaultMessage: `Per le utenze admin la mail con le credenziali viene automaticamente inviata all'utente.`,
          id: 'usersManagement.configuration.admin',
        }),
        secretariatCreate: intl.formatMessage({
          defaultMessage: `Per le utenze "Segreteria" la mail con le credenziali viene automaticamente inviata all'utente.`,
          id: 'usersManagement.configuration.secretariatCreate',
        }),
        secretariatEditUsers: intl.formatMessage({
          defaultMessage: 'utenti gestiti',
          id: 'usersManagement.configuration.secretariatEditUsers',
        }),
        secretariatEditList: intl.formatMessage({
          defaultMessage: 'Vedi/Modifica Lista',
          id: 'usersManagement.configuration.secretariatEditList',
        }),
      },
      listSecretariat: {
        title: intl.formatMessage({
          defaultMessage: 'Lista utenti gestiti da',
          id: 'usersManagement.configuration.listSecretariat.title',
        }),
        searchBar: intl.formatMessage({
          defaultMessage:
            'Cerca Nominativi per l’aggiunta in lista  (per Nome, Cognome o testata)...',
          id: 'usersManagement.configuration.listSecretariat.searchBar',
        }),
        noResults: intl.formatMessage({
          defaultMessage: '0 utenti gestiti dalla Segreteria',
          id: 'usersManagement.configuration.listSecretariat.noResults',
        }),
        snackbars: {
          successCreate: intl.formatMessage({
            defaultMessage: 'Utente aggiunto correttamente',
            id: 'usersManagement.configuration.listSecretariat.snackbars.successCreate',
          }),
          successDelete: intl.formatMessage({
            defaultMessage: 'Utente correttamente rimosso',
            id: 'usersManagement.configuration.listSecretariat.snackbars.successDelete',
          }),
        },
      },
    },
    buttons: {
      addUser: intl.formatMessage({
        defaultMessage: 'Nuovo Utente',
        id: 'usersManagement.buttons.addUser',
      }),
      filter: intl.formatMessage({
        defaultMessage: 'Filtra',
        id: 'usersManagement.buttons.filter',
      }),
      edit: intl.formatMessage({
        defaultMessage: 'Modifica',
        id: 'usersManagement.buttons.edit',
      }),
      remove: intl.formatMessage({
        defaultMessage: 'Elimina',
        id: 'usersManagement.buttons.remove',
      }),
      apply: intl.formatMessage({
        defaultMessage: 'Applica',
        id: 'usersManagement.buttons.apply',
      }),
      clearAll: intl.formatMessage({
        defaultMessage: 'Cancella tutto',
        id: 'usersManagement.buttons.clearAll',
      }),
      create: intl.formatMessage({
        defaultMessage: 'Crea Utente',
        id: 'usersManagement.buttons.create',
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Annulla',
        id: 'usersManagement.buttons.cancel',
      }),
      saveEditing: intl.formatMessage({
        defaultMessage: 'Salva modifiche',
        id: 'usersManagement.buttons.saveEditing',
      }),
    },
    tables: {
      users: {
        form: {
          categories: intl.formatMessage({
            defaultMessage: 'Categorie',
            id: 'usersManagement.tables.users.form.categories',
          }),
          allCategories: intl.formatMessage({
            defaultMessage: 'Tutte',
            id: 'usersManagement.tables.users.form.allCategories',
          }),
          search: intl.formatMessage({
            defaultMessage: 'Cerca per Nome Cognome...',
            id: 'usersManagement.tables.users.form.search',
          }),
        },
        tabs: {
          journalist: intl.formatMessage({
            defaultMessage: 'Giornalisti/Fotografi',
            id: 'usersManagement.tables.users.tabs.journalist',
          }),
          secretariat: intl.formatMessage({
            defaultMessage: 'Segreterie',
            id: 'usersManagement.tables.users.tabs.secretariat',
          }),
          admin: intl.formatMessage({
            defaultMessage: 'Admin',
            id: 'usersManagement.tables.users.tabs.admin',
          }),
        },
        columns: {
          name: intl.formatMessage({
            defaultMessage: 'Nome',
            id: 'usersManagement.tables.users.columns.name',
          }),
          surname: intl.formatMessage({
            defaultMessage: 'Cognome',
            id: 'usersManagement.tables.users.columns.surname',
          }),
          email: intl.formatMessage({
            defaultMessage: 'Email',
            id: 'usersManagement.tables.users.columns.email',
          }),
          organisation: intl.formatMessage({
            defaultMessage: 'Testata',
            id: 'usersManagement.tables.users.columns.organisation',
          }),
          category: intl.formatMessage({
            defaultMessage: 'Categoria',
            id: 'usersManagement.tables.users.columns.category',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Stato',
            id: 'usersManagement.tables.users.columns.status',
          }),
          createdAt: intl.formatMessage({
            defaultMessage: 'Creato il',
            id: 'usersManagement.tables.users.columns.createdAt',
          }),
          accounts: intl.formatMessage({
            defaultMessage: 'Account Gestiti',
            id: 'usersManagement.tables.users.columns.accounts',
          }),
          number: intl.formatMessage({
            defaultMessage: 'Numero tessera giornalistica',
            id: 'usersManagement.tables.users.columns.number',
          }),
          date: intl.formatMessage({
            defaultMessage: 'Data di nascita',
            id: 'usersManagement.tables.users.columns.date',
          }),
          genre: intl.formatMessage({
            defaultMessage: 'Genere',
            id: 'usersManagement.tables.users.columns.genre',
          }),
          nation: intl.formatMessage({
            defaultMessage: 'Nazione di nascita',
            id: 'usersManagement.tables.users.columns.nation',
          }),
          district: intl.formatMessage({
            defaultMessage: 'Provincia',
            id: 'usersManagement.tables.users.columns.district',
          }),
          city: intl.formatMessage({
            defaultMessage: 'Città',
            id: 'usersManagement.tables.users.columns.city',
          }),
          role: intl.formatMessage({
            defaultMessage: 'Tipologia Utente',
            id: 'usersManagement.tables.users.columns.role',
          }),
        },
      },
    },
    categories: {
      italianPhoto: intl.formatMessage({
        defaultMessage: 'Italian Photographer',
        id: 'usersManagement.categories.italianPhoto',
      }),
      foreignPhoto: intl.formatMessage({
        defaultMessage: 'Foreign Photographer',
        id: 'usersManagement.categories.foreignPhoto',
      }),
      italianPress: intl.formatMessage({
        defaultMessage: 'Italian Press',
        id: 'usersManagement.categories.italianPress',
      }),
      foreignPress: intl.formatMessage({
        defaultMessage: 'Foreign Press',
        id: 'usersManagement.categories.foreignPress',
      }),
      observerStand: intl.formatMessage({
        defaultMessage: 'Observer Stand',
        id: 'usersManagement.categories.observerStand',
      }),
      organisation: intl.formatMessage({
        defaultMessage: 'Ufficio Stampa Ospite',
        id: 'usersManagement.categories.organisation',
      }),
      webMaster: intl.formatMessage({
        defaultMessage: 'Website',
        id: 'usersManagement.categories.webMaster',
      }),
      radio: intl.formatMessage({
        defaultMessage: 'Radio',
        id: 'usersManagement.categories.radio',
      }),
      tv: intl.formatMessage({
        defaultMessage: 'Tv',
        id: 'usersManagement.categories.tv',
      }),
      techTv: intl.formatMessage({
        defaultMessage: 'Tecnici TV',
        id: 'usersManagement.categories.techTv',
      }),
    },
    messages: {
      success: {
        adminCreate: intl.formatMessage({
          defaultMessage: 'Utente Admin correttamente creato',
          id: 'usersManagement.messages.success.adminCreate',
        }),
        secretariatCreate: intl.formatMessage({
          defaultMessage: 'Utente Segreteria correttamente creato',
          id: 'usersManagement.messages.success.secretariatCreate',
        }),
        journalistCreate: intl.formatMessage({
          defaultMessage: 'Utente Giornalista correttamente creato',
          id: 'usersManagement.messages.success.journalistCreate',
        }),
        adminUpdate: intl.formatMessage({
          defaultMessage: 'Utente Admin correttamente modificato',
          id: 'usersManagement.messages.success.adminUpdate',
        }),
        journalistUpdate: intl.formatMessage({
          defaultMessage: 'Utente Giornalista correttamente modificato',
          id: 'usersManagement.messages.success.journalistUpdate',
        }),
        secretariatUpdate: intl.formatMessage({
          defaultMessage: 'Utente Segreteria correttamente modificato',
          id: 'usersManagement.messages.success.secretariatUpdate',
        }),
      },
      error: {
        journalistCredentials: intl.formatMessage({
          defaultMessage: `Errore nell'invio delle credenziali`,
          id: 'usersManagement.messages.error.journalistCredentials',
        }),
      },
    },
  },
});
