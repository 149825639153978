const UserProfileIcon = ({ fill = 'black' }: { fill?: string }) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0007 0.833984C4.94065 0.833984 0.833984 4.94065 0.833984 10.0007C0.833984 15.0607 4.94065 19.1673 10.0007 19.1673C15.0607 19.1673 19.1673 15.0607 19.1673 10.0007C19.1673 4.94065 15.0607 0.833984 10.0007 0.833984ZM5.48148 15.7573C5.87565 14.9323 8.27732 14.1257 10.0007 14.1257C11.724 14.1257 14.1348 14.9323 14.5198 15.7573C13.2732 16.7473 11.7057 17.334 10.0007 17.334C8.29565 17.334 6.72815 16.7473 5.48148 15.7573ZM15.8307 14.4282C14.5198 12.8332 11.339 12.2923 10.0007 12.2923C8.66232 12.2923 5.48148 12.8332 4.17065 14.4282C3.23565 13.1998 2.66732 11.669 2.66732 10.0007C2.66732 5.95815 5.95815 2.66732 10.0007 2.66732C14.0431 2.66732 17.334 5.95815 17.334 10.0007C17.334 11.669 16.7657 13.1998 15.8307 14.4282ZM10.0007 4.50065C8.22232 4.50065 6.79232 5.93065 6.79232 7.70898C6.79232 9.48732 8.22232 10.9173 10.0007 10.9173C11.779 10.9173 13.209 9.48732 13.209 7.70898C13.209 5.93065 11.779 4.50065 10.0007 4.50065ZM10.0007 9.08398C9.23982 9.08398 8.62565 8.46982 8.62565 7.70898C8.62565 6.94815 9.23982 6.33398 10.0007 6.33398C10.7615 6.33398 11.3757 6.94815 11.3757 7.70898C11.3757 8.46982 10.7615 9.08398 10.0007 9.08398Z"
      fill={fill}
    />
  </svg>
);

export default UserProfileIcon;
