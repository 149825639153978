import { Grid, Typography } from '@mui/material';
import {
  FooterContainer,
  LogoColumn,
  LinkColumn,
  SocialColumn,
} from './PageFooter.styles';
import { Image } from 'components';
import { useResponsive, useTranslations } from 'hooks';

const PageFooter = () => {
  const transalations = useTranslations();
  const i18n = transalations.general.footer;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <FooterContainer>
      <Grid container>
        {/* Logo */}
        <LogoColumn container item lg={4} xs={12} order={isDesktop ? 2 : 1}>
          <a target="_blank" href="https://eu.puma.com/it/it/home">
            <Image src="/assets/logos/puma.png" className="footer-logo" />
          </a>
          <a target="_blank" href="https://www.emirates.com/us/english/">
            <Image src="/assets/logos/emirates.png" className="footer-logo" />
          </a>
          <a target="_blank" href="https://www.msccrociere.it/">
            <Image src="/assets/logos/msc.png" className="footer-logo" />
          </a>
          <a target="_blank" href="https://www.konami.com/efootball/en/">
            <Image src="/assets/logos/konami.png" className="footer-logo" />
          </a>
        </LogoColumn>

        {/* Link */}
        <LinkColumn container item lg={4} xs={12} order={isDesktop ? 1 : 2}>
          <a
            target="_blank"
            href={'https://www.acmilan.com/en/privacy'}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <Typography variant={'caption'}>{i18n.privacy}</Typography>
          </a>
          <a
            target="_blank"
            href={'https://www.acmilan.com/en/cookie-management-policy'}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <Typography variant={'caption'}>{i18n.cookie}</Typography>
          </a>
          <a
            target="_blank"
            href={'https://www.acmilan.com/en'}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <Typography variant={'caption'}>{i18n.acmilancom}</Typography>
          </a>
        </LinkColumn>

        {/* Social */}
        <SocialColumn container item lg={4} xs={12} order={3}>
          <Typography variant="caption" color="common.white" mr={1.5}>
            Follow us on
          </Typography>
          <a target="_blank" href={'https://www.twitch.tv/acmilan'}>
            <Image
              src="/assets/logos/twitch.png"
              className="footer-social-icon"
            />
          </a>
          <a target="_blank" href={'https://www.facebook.com/ACMilan'}>
            <Image src="/assets/logos/fb.png" className="footer-social-icon" />
          </a>
          <a target="_blank" href={'https://www.instagram.com/acmilan'}>
            <Image
              src="/assets/logos/instagram.png"
              className="footer-social-icon"
            />
          </a>
          <a target="_blank" href={'https://it.linkedin.com/company/ac-milan'}>
            <Image
              src="/assets/logos/linkedin.png"
              className="footer-social-icon"
            />
          </a>
          <a
            target="_blank"
            href={'https://www.tiktok.com/@acmilan?language=it'}
          >
            <Image
              src="/assets/logos/tiktok.png"
              className="footer-social-icon"
            />
          </a>

          <a target="_blank" href={'https://www.youtube.com/acmilan'}>
            <Image
              src="/assets/logos/youtube.png"
              className="footer-social-icon"
            />
          </a>
          <a target="_blank" href={'https://twitter.com/acmilan'}>
            <Image
              src="/assets/logos/tiktok.png"
              className="footer-social-icon"
            />
          </a>
          <a
            target="_blank"
            href={'https://www.acmilan.com/it/fan/ac-milan-music'}
          >
            <Image
              src="/assets/logos/music.png"
              className="footer-social-icon"
            />
          </a>
        </SocialColumn>
      </Grid>
    </FooterContainer>
  );
};
export { PageFooter };
