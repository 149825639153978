import { Box, Stack, Typography } from '@mui/material';
import { IBlockContentProp } from './BlockContent.types';
import { UploadIllustration } from 'assets';
import { useTranslations } from 'hooks';

export default function BlockContent({
  title = 'Seleziona file',
}: IBlockContentProp) {
  const i18n = useTranslations();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {i18n.general.form.upload.dropFileOrClick}&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            {i18n.general.form.upload.browse}
          </Typography>
          &nbsp; {i18n.general.form.upload.onYourMachine}
        </Typography>
      </Box>
    </Stack>
  );
}
