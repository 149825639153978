import { enUS as MUIEnLocale, itIT as MUIItLocale } from '@mui/material/locale';
import { it as DateFNSIt, enUS as DateFnsEn } from 'date-fns/locale';
import { SettingsValueProps } from './components/settings/type';
import { ILang, Locales } from 'types/intl';

// LAYOUT
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: true,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs: ILang[] = [
  {
    label: 'Italiano',
    languageLabel: 'Lingua',
    value: Locales.IT,
    systemValue: MUIItLocale,
    dateFnsValue: DateFNSIt,
    icon: '/assets/icons/flags/ic_flag_it.svg',
  },
  {
    label: 'English',
    languageLabel: 'Language',
    value: Locales.EN,
    systemValue: MUIEnLocale,
    dateFnsValue: DateFnsEn,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];
export const defaultLocale: Locales = Locales.IT;

export const getLangByLocale = (locale: Locales) =>
  allLangs.find((lang: ILang) => lang.value === locale);

export const defaultLang = getLangByLocale(defaultLocale) || allLangs[0];
