import { Avatar as MUIAvatar, Box, Typography, useTheme } from '@mui/material';
import { IAvatarProps } from './Avatar.types';
import { getInitials } from './utils';
import { UserProfileIcon } from 'assets';

const Avatar = ({ fullName, ...other }: IAvatarProps) => {
  const theme = useTheme();

  const avatarData = fullName ? (
    <Typography variant="subtitle2" sx={{ letterSpacing: -1 }}>
      {getInitials(fullName)}
    </Typography>
  ) : (
    <Box width={20} height={20}>
      <UserProfileIcon fill={theme.palette.text.secondary} />
    </Box>
  );

  return (
    <MUIAvatar color="primary" {...other}>
      {avatarData}
    </MUIAvatar>
  );
};

export { Avatar };
