import { Paper, styled } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  GridOverlay,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useState } from 'react';

const StyledDataGrid = styled(DataGrid)(({ theme, checkboxSelection }) => ({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeaderCheckbox':
    {
      paddingLeft: checkboxSelection ? 10 : 0,
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeadersInner': {
    backgroundColor: theme.palette.background.neutral,
    borderRadius: '8px',
    paddingLeft: checkboxSelection ? 0 : '30px',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    paddingLeft: checkboxSelection ? 10 : '40px',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  // '&.MuiDataGrid-root .MuiCheckbox-root': {
  //   marginRight: 30,
  // },
}));

interface ICustomTableProps {
  tableData: object[];
  columns: GridColumns;
  checkboxSelection?: boolean;
  customHeader?: JSX.Element;
  handlePageChange: (n: number) => void;
  handlePageSizeChange: (n: number) => void;
  handleSortModelChange: (a: GridSortModel) => void;
  handleSelectionModelChange: (ms: GridSelectionModel) => void;
  customNotFoundMsg?: string;
  customDefaultPageSize?: number;
}

export const DEFAULT_TABLE_PAGE_SIZE = 25;

export const Table = ({
  tableData,
  columns,
  checkboxSelection = false,
  handlePageChange,
  handlePageSizeChange,
  handleSortModelChange,
  handleSelectionModelChange,
  customHeader,
  customNotFoundMsg = '',
  customDefaultPageSize,
  ...rest
}: ICustomTableProps & Partial<DataGridProps>) => {
  const [pageSize, setPageSize] = useState<number>(
    customDefaultPageSize || DEFAULT_TABLE_PAGE_SIZE
  );

  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>{customNotFoundMsg || 'No Results found'} </div>
      </GridOverlay>
    );
  };

  return (
    <Paper
      elevation={1}
      sx={{
        minHeight: '500px',
        width: '100%',
        marginBottom: '50px',
        '& .super-app-theme--header': {
          backgroundColor: 'rgba(255, 7, 0, 0.55)',
        },
      }}
    >
      {customHeader && customHeader}
      <StyledDataGrid
        rows={tableData}
        columns={columns}
        pageSize={pageSize}
        // loading={tableData.length <= 0}
        rowsPerPageOptions={[25, 50, 100]}
        checkboxSelection={checkboxSelection}
        paginationMode={'server'}
        sortingMode={'server'}
        filterMode={'server'}
        autoHeight
        rowHeight={72}
        components={{
          NoRowsOverlay: customNoRowsOverlay,
          NoResultsOverlay: customNoRowsOverlay,
        }}
        onPageChange={(page: number) => {
          handlePageChange(page + 1);
        }}
        onPageSizeChange={(pageSize: number) => {
          handlePageSizeChange(pageSize);
          setPageSize(pageSize);
        }}
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          handleSelectionModelChange(selectionModel);
        }}
        onSortModelChange={(model: GridSortModel) => {
          handleSortModelChange(model);
        }}
        {...rest}
      />
    </Paper>
  );
};
