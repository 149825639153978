const TrashIcon = ({ fillColor = 'black' }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4681 5.0005H14.3014V3.60883C14.2819 3.07535 14.0516 2.57138 13.6611 2.20741C13.2705 1.84344 12.7516 1.64916 12.2181 1.66716H9.7181C9.18456 1.64916 8.66565 1.84344 8.27512 2.20741C7.8846 2.57138 7.65432 3.07535 7.63477 3.60883V5.0005H3.4681C3.24709 5.0005 3.03512 5.0883 2.87884 5.24458C2.72256 5.40086 2.63477 5.61282 2.63477 5.83383C2.63477 6.05484 2.72256 6.26681 2.87884 6.42309C3.03512 6.57937 3.24709 6.66716 3.4681 6.66716H4.30143V15.8338C4.30143 16.4969 4.56482 17.1328 5.03367 17.6016C5.50251 18.0704 6.13839 18.3338 6.80143 18.3338H15.1348C15.7978 18.3338 16.4337 18.0704 16.9025 17.6016C17.3714 17.1328 17.6348 16.4969 17.6348 15.8338V6.66716H18.4681C18.6891 6.66716 18.9011 6.57937 19.0574 6.42309C19.2136 6.26681 19.3014 6.05484 19.3014 5.83383C19.3014 5.61282 19.2136 5.40086 19.0574 5.24458C18.9011 5.0883 18.6891 5.0005 18.4681 5.0005ZM9.30143 3.60883C9.30143 3.4755 9.47643 3.33383 9.7181 3.33383H12.2181C12.4598 3.33383 12.6348 3.4755 12.6348 3.60883V5.0005H9.30143V3.60883ZM15.9681 15.8338C15.9681 16.0548 15.8803 16.2668 15.724 16.4231C15.5677 16.5794 15.3558 16.6672 15.1348 16.6672H6.80143C6.58042 16.6672 6.36846 16.5794 6.21218 16.4231C6.0559 16.2668 5.9681 16.0548 5.9681 15.8338V6.66716H15.9681V15.8338Z"
        fill={fillColor}
      />
      <path
        d="M8.4681 14.166C8.68911 14.166 8.90107 14.0782 9.05735 13.9219C9.21363 13.7657 9.30143 13.5537 9.30143 13.3327V9.99935C9.30143 9.77834 9.21363 9.56637 9.05735 9.41009C8.90107 9.25381 8.68911 9.16602 8.4681 9.16602C8.24709 9.16602 8.03512 9.25381 7.87884 9.41009C7.72256 9.56637 7.63477 9.77834 7.63477 9.99935V13.3327C7.63477 13.5537 7.72256 13.7657 7.87884 13.9219C8.03512 14.0782 8.24709 14.166 8.4681 14.166Z"
        fill={fillColor}
      />
      <path
        d="M13.4681 14.166C13.6891 14.166 13.9011 14.0782 14.0574 13.9219C14.2136 13.7657 14.3014 13.5537 14.3014 13.3327V9.99935C14.3014 9.77834 14.2136 9.56637 14.0574 9.41009C13.9011 9.25381 13.6891 9.16602 13.4681 9.16602C13.2471 9.16602 13.0351 9.25381 12.8788 9.41009C12.7226 9.56637 12.6348 9.77834 12.6348 9.99935V13.3327C12.6348 13.5537 12.7226 13.7657 12.8788 13.9219C13.0351 14.0782 13.2471 14.166 13.4681 14.166Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default TrashIcon;
