import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ILogoProps } from './Logo.types';
import { LogoFull, LogoSymbol } from 'assets';

const Logo = ({ link, iconOnly, sx, textColor }: ILogoProps) => {
  const logo = (
    <Box sx={{ ...sx }}>
      {iconOnly ? <LogoSymbol /> : <LogoFull color={textColor} />}
    </Box>
  );

  if (!link) {
    return <>{logo}</>;
  }

  return <RouterLink to={link}>{logo}</RouterLink>;
};

export { Logo };
