import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { shallow } from 'zustand/shallow';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { ScrollToTop } from 'components';
import { ProgressBarStyle } from 'components/ProgressBar/ProgressBar.styles';
import MotionLazyContainer from 'components/animations/MotionLazyContainer/MotionLazyContainer';
import ThemeSettings from 'components/settings';
import { useCreditsFiltersStore } from 'pages/Calendar/Match/CreditManagement/useCreditsFiltersStore';
import { LoadingProvider, SnackbarProvider } from 'providers';
import Router from 'routes';
import { useGlobalStore } from 'stores';
import ThemeProvider from 'theme';
import './theme/global.css';

const { reset: resetCreditsFilters } = useCreditsFiltersStore.getState();

export default function App() {
  const { pathname } = useLocation();

  const adapterLocale = useGlobalStore(
    state => state.activeLang.dateFnsValue,
    shallow
  );

  useEffect(() => {
    if (!pathname.includes('credit-management')) resetCreditsFilters();
  }, [pathname]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
    >
      <SettingsProvider>
        <CollapseDrawerProvider>
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeSettings>
                <SnackbarProvider>
                  <ProgressBarStyle />
                  <ScrollToTop />
                  <LoadingProvider />
                  <Router />
                </SnackbarProvider>
              </ThemeSettings>
            </ThemeProvider>
          </MotionLazyContainer>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
