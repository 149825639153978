import { ActionsWrapper, ModalButton } from './ModalActions.styles';
import { ModalActionsProps } from './ModalActions.types';
import { useTranslations } from 'hooks';
import { IModalButton } from 'hooks/useModal/useModal.types';

const ModalActions = ({
  showActionsDivider,
  buttons,
  handleClose,
  stretchButtons,
  fullWidthButtons,
}: ModalActionsProps) => {
  const i18n = useTranslations();

  const handleButtonClick = (
    customClickHandler?: () => void,
    disableClose?: boolean
  ) => {
    customClickHandler?.();
    if (!disableClose) handleClose();
  };

  const renderButton = (
    {
      text,
      colorVariant = 'primary',
      onClick,
      disabled,
      disableClose,
    }: IModalButton,
    idx: number
  ) => {
    const isOutlinedSecondary = colorVariant === 'outlinedSecondary';
    const isPrimary = colorVariant === 'primary';
    return (
      <ModalButton
        key={idx}
        variant={isOutlinedSecondary ? 'outlined' : 'contained'}
        color={isOutlinedSecondary ? 'secondary' : colorVariant}
        onClick={() => handleButtonClick(onClick, disableClose)}
        stretchButtons={stretchButtons}
        fullWidthButtons={fullWidthButtons}
        disabled={disabled}
      >
        {text || i18n.general.buttons[isPrimary ? 'ok' : 'cancel']}
      </ModalButton>
    );
  };

  return (
    <ActionsWrapper showActionsDivider={showActionsDivider}>
      {buttons?.map(renderButton)}
    </ActionsWrapper>
  );
};

export { ModalActions };
