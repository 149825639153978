import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '2cm',
  },
  newspaper: { textTransform: 'uppercase', fontSize: 34, paddingTop: '4cm' },
  name: { fontSize: 28, paddingTop: '0.8cm' },
  seat: { fontSize: 28, paddingTop: '4cm' },
  matchName: { paddingTop: '4cm', textTransform: 'uppercase' },
  matchInfo: { paddingTop: '0.35cm', fontSize: 14 },
});
