import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ICardListItemWrapperProps } from './CardListItem.types';

export const Wrapper = styled(Paper, {
  shouldForwardProp: prop => prop !== 'clickable',
})<ICardListItemWrapperProps>(({ clickable, theme }) => ({
  position: 'relative',
  marginBlock: theme.spacing(2.5),
  padding: theme.spacing(2.5),
  borderRadius: '15px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  minHeight: 100,
  columnGap: theme.spacing(2.5),
  boxShadow: theme.shadows[12],
  transition: '.2s ease-in-out all',
  ...(clickable && {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  }),
}));

export const ProgressWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1.5),
}));
