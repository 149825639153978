import { Navigate, useLocation } from 'react-router-dom';

import { AuthGuardProps } from './AuthGuard.types';
import * as PATHS from 'constants/paths';
import { useSnackbar, useTranslations } from 'hooks';
import { useAuthSelector, useGlobalSelector, useGlobalStore } from 'stores';
import { UserLitteralTypes } from 'types/user';

const AuthGuard = ({ children, roles }: AuthGuardProps) => {
  const { isAuthenticated, tokens, isLoggingOut } = useAuthSelector();
  const { user, handleLogout } = useAuthSelector();
  const { requestedLocation } = useGlobalSelector();
  const { errorSnackbar } = useSnackbar();
  const i18n = useTranslations();

  const { pathname } = useLocation();

  const handlePermssionDenied = () => {
    handleLogout();
    errorSnackbar(i18n.general.errors.permissionDenied);
    return <Navigate to={PATHS.LOGIN} />;
  };

  if (!isAuthenticated) {
    if (pathname !== requestedLocation && !isLoggingOut)
      useGlobalStore.setState({ requestedLocation: pathname });

    return <Navigate to={PATHS.LOGIN} />;
  }

  if (isAuthenticated && tokens?.mustChangePassword)
    return <Navigate to={PATHS.NEW_PASSWORD} />;

  if (requestedLocation && pathname !== requestedLocation) {
    useGlobalStore.setState({ requestedLocation: null });
    return <Navigate to={requestedLocation} />;
  }

  //TODO:: Handle, IN A BETTER WAY, the logic Admin logging in milan-fo-dev and Journalist logging in milan-dev ::
  if (process.env.REACT_APP_FO_WEB_URL && process.env.REACT_APP_WEB_URL) {
    if (
      (user?.type === UserLitteralTypes.ADMIN &&
        window.location.href.includes(process.env.REACT_APP_FO_WEB_URL)) ||
      (user?.type !== UserLitteralTypes.ADMIN &&
        window.location.href.includes(process.env.REACT_APP_WEB_URL))
    )
      handlePermssionDenied();
  }

  if (user?.type && roles?.length && !roles.includes(user.type))
    handlePermssionDenied();

  return <>{children}</>;
};

export { AuthGuard };
