import { Box } from '@mui/material';

import { useState } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';

import { Background, MainStyle } from './DashboardLayout.styles';
import { DashboardHeader } from './components/Header/Header';
import { NavbarVertical } from './components/Navbar/NavbarVertical';
import { PageFooter } from 'components';
import * as PATHS from 'constants/paths';
import { useCollapseDrawer } from 'hooks';

const DashboardLayout = () => {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const hasBackground = !!matchPath(PATHS.CALENDAR_ROOT, pathname);

  const densePadding = !!matchPath(
    PATHS.CALENDAR_CREDIT_MANAGEMENT_ID,
    pathname
  );

  const renderFooter = () =>
    process.env.REACT_APP_FO_WEB_URL &&
    window.location.href.includes(process.env.REACT_APP_FO_WEB_URL) ? (
      <PageFooter />
    ) : null;

  return (
    <>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
          position: 'relative',
        }}
      >
        <DashboardHeader
          isCollapse={isCollapse}
          onOpenSidebar={() => setOpen(true)}
        />

        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />

        {hasBackground && <Background />}

        <MainStyle
          collapseClick={collapseClick}
          layoutHasBackground={hasBackground}
          densePadding={densePadding}
        >
          <Outlet />
        </MainStyle>
      </Box>
      {renderFooter()}
    </>
  );
};

export { DashboardLayout };
