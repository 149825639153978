import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function DatePicker(theme: Theme) {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          // Toolbar
          '& .MuiDatePickerToolbar-root': {
            borderTopLeftRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,

            // Toolbar Title
            '& .MuiTypography-overline': {
              color: theme.palette.common.white,
              opacity: 0.75,
              marginBottom: theme.spacing(1),
            },
            // Toolbar Title Date
            '& .MuiDatePickerToolbar-title': {
              textTransform: 'capitalize',
              marginBottom: theme.spacing(1.5),
            },

            // Toolbar Icon Button
            '& .MuiPickersToolbar-penIconButton': {
              display: 'none',
            },
          },
          // Picker Dialog
          '& .MuiCalendarPicker-root': {
            textTransform: 'capitalize',

            // Picker Dialog Days
            '& .MuiPickersDay-root': {
              fontWeight: theme.typography.fontWeightMedium,
            },
            // Picker Dialog Days selected
            '& .MuiPickersDay-root.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
