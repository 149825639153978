import { ReactNode } from 'react';
import { IncomingOptions } from 'use-http';
import {
  IAccreditationEnumsStatus,
  IAccreditationStatus,
  SeatType,
} from './accreditations';
import { ICustomApiError } from './error';
import { MatchEnumsCompetition } from './match';
import {
  UserEnumsCategory,
  UserEnumStatus,
  UserEnumsTypes,
  UserLitteralTypes,
} from './user';

export interface IHttpProviderProps {
  children: ReactNode;
  baseUrl?: string;
  customOptions?: IncomingOptions;
}

export interface IInterceptorReq {
  options: RequestInit;
  url?: string;
  path?: string;
  route?: string;
}

export interface ICustomRetryOpts {
  attempt: number;
  error?: Error | ICustomApiError;
  response?: Response;
}

export interface IRequestFilters {
  sortBy?: string;
  sortOrder?: SortingEnumsCode;
  page?: number;
  pageSize?: number;
  key?: string;
}

export enum SortingCode {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export type SortingEnumsCode = `${SortingCode}`;

export type UserTabsValue = `${UserLitteralTypes}`;
export interface IUsersFilters extends IRequestFilters {
  type?: UserEnumsTypes[];
  displayName?: string;
  surname?: string;
  email?: string;
  organisation?: string;
  category?: UserEnumsCategory;
  status?: UserEnumStatus;
  enabled?: string;
}

export interface ICalendarMatchFilters extends IRequestFilters {
  competitions?: MatchEnumsCompetition[];
  fromDate?: string;
  toDate?: string;
}

export interface IAccreditationsFilters extends IRequestFilters {
  matchId?: number;
  displayName?: string;
  status?: IAccreditationEnumsStatus | IAccreditationEnumsStatus[];
  organisation?: string;
  category?: UserEnumsCategory;
  seatType?: SeatType;
  sortBy?: string;
  sortOrder?: SortingEnumsCode;
}

export interface IMatchFilters extends IRequestFilters {
  name?: string;
}

export interface IHttpHelperProps<T> {
  useLoader?: boolean;
  setData?: React.Dispatch<React.SetStateAction<T>>;
}

export interface IHttpHelperExtendedProps<T> extends IHttpHelperProps<T> {
  id?: string | number;
  email?: string;
}

export interface IHttpMutateHelperProps<T, U>
  extends IHttpHelperExtendedProps<T> {
  body?: U;
}
export interface IHttpNoResponseHelperProps<T> {
  useLoader?: boolean;
  body?: T;
}
export interface IUpdatePasswordHelperProps<T, U>
  extends IHttpMutateHelperProps<T, U> {
  isFirstChange?: boolean;
  code?: string;
}
export interface IDeletionAccreditationHelperProps<T>
  extends IHttpHelperProps<T> {
  matchId?: number;
  seasonId?: number;
}

export interface IAccreditationExportHelperProps<T>
  extends IHttpHelperExtendedProps<T> {
  accreditationStatus?: IAccreditationStatus;
}
