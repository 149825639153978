import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { IAccreditationStatus } from 'types/accreditations';
import { IAccreditationsFilters } from 'types/httpClient';
import {
  ICreditFiltersStore,
  ICreditFiltersStoreDefaultValues,
} from 'types/stores';
import { UserLitteralCategory } from 'types/user';

const SORTING_FILTERS: IAccreditationsFilters = {
  sortBy: 'accreditation.organisation',
  sortOrder: 'ASC',
};

const LANDING_FILTERS: IAccreditationsFilters = {
  status: [IAccreditationStatus.APPROVED, IAccreditationStatus.PENDING],
};

const INITIAL_FILTERS: IAccreditationsFilters = {
  ...SORTING_FILTERS,
  ...LANDING_FILTERS,
};

const defaulValues: ICreditFiltersStoreDefaultValues = {
  filters: INITIAL_FILTERS,
};

const useStore = create(
  subscribeWithSelector<ICreditFiltersStore>((set, get) => ({
    ...defaulValues,
    setFilters: filters => {
      const nextFilters = {
        ...cleanFilters(filters), // Remove "All" values
        ...SORTING_FILTERS, // Fixed sorting
      };

      if (typeof nextFilters.status === 'object') delete nextFilters.status; // Remove landing filters

      set({ filters: nextFilters });
    },

    deleteFilter: key => {
      const { filters, setFilters } = get();
      const nextFilters = { ...filters };
      delete nextFilters[key];
      setFilters(nextFilters);
    },
    reset: () => set(prev => ({ ...prev, ...defaulValues })),
  }))
);

const cleanFilters = (
  values: IAccreditationsFilters
): IAccreditationsFilters => {
  const cleanValues = { ...values };
  if (cleanValues.category === UserLitteralCategory.ALL)
    delete cleanValues.category;
  if (cleanValues.status === IAccreditationStatus.ALL)
    delete cleanValues.status;

  return cleanValues;
};

export { useStore as useCreditsFiltersStore };
