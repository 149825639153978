import { styled } from '@mui/material';
import {
  IIconHeadingWrapperProps,
  IIconWrapperProps,
} from './IconHeading.types';

export const IconHeadingWrapper = styled('div', {
  shouldForwardProp: prop =>
    !['showDivider', 'inverse'].includes(prop as string),
})<IIconHeadingWrapperProps>(({ inverse, showDivider, theme }) => ({
  display: 'flex',
  textAlign: inverse ? 'right' : 'unset',
  ...(inverse
    ? { paddingLeft: theme.spacing(3) }
    : { paddingRight: theme.spacing(3) }),
  ...(inverse && { flexDirection: 'row-reverse' }),
  ...(showDivider && {
    borderLeft: inverse ? `1px solid ${theme.palette.grey[300]}` : 'none',
    borderRight: inverse ? 'none' : `1px solid ${theme.palette.grey[300]}`,
  }),
}));

export const IconWrapper = styled('div', {
  shouldForwardProp: prop =>
    !['bgColor', 'inverse', 'titleSpacing'].includes(prop as string),
})<IIconWrapperProps>(({ bgColor, inverse, titleSpacing, theme }) => ({
  width: 60,
  height: 60,
  backgroundColor: bgColor || theme.palette.grey[200],
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(inverse
    ? { marginLeft: titleSpacing || theme.spacing(3) }
    : { marginRight: titleSpacing || theme.spacing(3) }),
}));
