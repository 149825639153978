import { Box } from '@mui/material';
import { m } from 'framer-motion';

import { varContainer } from '../variants';
import { IMotionContainerProps } from './MotionContainer.types';

export default function MotionContainer({
  animate,
  action = false,
  children,
  ...other
}: IMotionContainerProps) {
  if (action) {
    return (
      <Box
        component={m.div}
        initial={false}
        animate={animate ? 'animate' : 'exit'}
        variants={varContainer()}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component={m.div}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  );
}
