import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')({
  display: 'flex',
  height: '100vh',
});

export const LeftCol = styled('div')(({ theme }) => ({
  display: 'none',
  flex: '0 0 50%',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const RightCol = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 0,
  flex: '0 0 50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.spacing(4)})`,
  margin: 'auto',
  paddingBlock: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    width: '75%',
    maxWidth: '500px',
  },
}));
