const ClearAllIcon = ({ fill = 'black' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.21094 13H19.2109V11H5.21094V13ZM3.21094 17H17.2109V15H3.21094V17ZM7.21094 7V9H21.2109V7H7.21094Z"
        fill={fill}
      />
    </svg>
  );
};

export default ClearAllIcon;
